@import 'src/theme/theme';

.featured-boxes-box {
  align-items: center;
  background-color: var(--bs-color-3);
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  display: flex;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  width: 85%;

  @include media-breakpoint-up(sm) {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    padding: 24px;
  }


  &-title {
    font-family: $headings-font-family;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(lg) {
      font-size: 24px;
    }
  }

  &-subtitle {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }
  }

  &-icon {
    align-items: center;
    background-color: var(--bs-color-2);
    border-radius: 8px;
    display: flex;
    flex: 1 0 50px;
    height: 50px;
    justify-content: center;
    margin-right: 16px;

    @include media-breakpoint-up(lg) {
      flex: 1 0 80px;
      height: 80px;
      margin-right: 24px;
    }

    img {
      width: 40%;
    }
  }
}
