@import './src/theme/theme';

.loading-cards {
  overflow: hidden;
  width: 100%;

  .row {
    flex-wrap: nowrap;
    margin-left: -6px;
    margin-right: -6px;


    @include media-breakpoint-up(md) {
      margin-left: -12px;
      margin-right: -12px;
    }


    > * {
      padding-left: 6px;
      padding-right: 6px;

      @include media-breakpoint-up(md) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
}
