@import 'src/theme/theme';

.partner {
  align-items: center;
  background-color: var(--bs-card-bg);
  border: $card-border-width solid var(--bs-card-border-color);
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  height: 250px;
  justify-content: center;
  padding: 40px 32px;
  
  &-logo {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    margin-bottom: 32px;
    margin-top: 16px;
    max-width: 180px;
  }
}
