@import './src/theme/theme';

.toast-progress {
  &-fill {
    background-color: var(--bs-primary);
    height: 2px;
    opacity: 1;
    transition: width 7s cubic-bezier(0.5, 0, 0.5, 1);
    width: 0;
  }

  &.none .toast-progress-fill,
  &.signed .toast-progress-fill {
    width: 3%;
  }

  &.sent .toast-progress-fill,
  &.pending .toast-progress-fill,
  &.success .toast-progress-fill {
    width: 90%;
  }

  &.success .toast-progress-fill {
    opacity: 0;
    transition: width 0.1s linear, opacity 0.2s ease-in-out 0.3s;
    width: 100% !important;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.timedOut,
  &.fail { .toast-progress-fill {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }}
}
