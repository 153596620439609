@import '/src/theme/theme';

.campaign-timer-numbers {
  font-size: 14px;
}

.campaign-timer-numbers-wrapper {
  margin-left: 4px;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }

  p {
    margin-bottom: 0;
  }
}

.campaign-timer-numbers-label {
  color: var(--bs-silver);
  font-size: 5px;
  font-weight: normal;
  line-height: 16px;
}
