@import 'src/theme/theme';

.subdomain-faq {
  margin: 0 auto;
  max-width: 900px;

  .accordion {
    &-item {
      background-color: transparent;
    }

    &-button {
      background-color: transparent;
      border-radius: 6px !important;

      &:hover {
        background-color: var(--bs-color-1);
      }
    }
  }
}
