@import './src/theme/theme';

.collection-card-details {
  bottom: 0;
  padding: 21px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 3;

  @include media-breakpoint-up(md) {
    padding: 24px;
  }

  a {
    text-decoration: none;
  }
}

.collection-card-description {
  -webkit-box-orient: vertical;
  display: block;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  font-size: 16px;
  height: calc(1.2em * 2);
  -webkit-line-clamp: 2;
  line-height: 1.2;
  overflow: hidden;
  padding: 0 !important;
  position: relative;
  text-overflow: ellipsis;

  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}
