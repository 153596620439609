@import './src/theme/theme';

.toast-body {
  font-size: 12px;
  padding: 0.75rem 1.5em;
}

.tx-status-content {
  .tx-status-address {
    color: var(--bs-silver);
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      font-size: 14px;
    }
  }
}
