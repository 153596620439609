.campaign-image {
  margin-bottom: 40px;

  &-inner {
    padding-top: 100%;
    position: relative;
  }
    
  &-img {
    border-radius: 12px;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  &-blur {
    filter: blur(15px);
    height: calc(100% - 10%);
    left: 3%;
    object-fit: cover;
    object-position: center;
    opacity: 0.3;
    position: absolute;
    top: 10%;
    width: calc(100% - 6%);
    
  }
}
