@import 'src/theme/theme';

.feed-card-infos {
  font-size: 14px;
  line-height: 1.35;
  padding: 12px 32px 12px 8px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    padding: 28px;
  }

  &-p {
    margin-bottom: 2px;

    @include media-breakpoint-up(md) {
      margin-bottom: 4px;
    }

    > * {
      display: inline-block;
    }
  }
}

.feed-card-time {
  color: var(--bs-silver);
  font-size: 12px;

  @include media-breakpoint-up(md) {
    font-size: 14px;
  }
}
