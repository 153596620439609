@import 'src/theme/theme';

.tiers-header {
  margin-top: 50px;
  text-align: center;

  &-title {
    font-family: $headings-font-family;
    font-size: 18px;
    font-weight: 700;
  }

  &-text {
    color: var(--bs-silver);
  }
}
