@import '../../../theme/theme';

.multi-range {
  position: relative;

  .multi-range-slider-content {
    position: relative;
    width: 200px;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .multi-range-slider-range {
    border-radius: 3px;
    height: 5px;
    position: absolute;
  }

  .multi-range-slider-track {
    background-color: rgba(var(--bs-silver-rgb), 0.2);
    height: 5px;
    width: 200px;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  .multi-range-slider-range {
    background-color: var(--bs-primary);

    top: 0;
  }

  .multi-range-slider-values {
    color: var(--bs-text-primary);
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-weight: bold;
    
    @include media-breakpoint-up(md) {
      min-width: 9.3em;
      text-align: center;
    }
  }

  .multi-range-slider-label {
    color: #596380;
    font-size: 16px;
    font-weight: bold;
  }

  .thumb,
  .thumb::-webkit-slider-thumb {
    appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    height: 0;
    outline: none;
    pointer-events: none;
    position: absolute;
    top: 1px;
    width: 200px;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

  }

  .thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: ew-resize;
    height: 16px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
    width: 10px;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: ew-resize;
    height: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
    width: 18px;
  }

}
