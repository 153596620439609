@import './src/theme/theme';

.asset-card {
  &-creator {
    margin-top: 8px;

    @include media-breakpoint-up(md) {
      margin-top: 16px;
    }
  }
}
