.toast-header {
  border-bottom: 0;
  min-height: 47px;
  padding: 0.5rem 1.5em;

  &-icon {
    align-items: center;
    background: var(--bs-silver);
    border-radius: 100px;
    display: flex;
    font-size: 10px;
    height: 16px;
    justify-content: center;
    width: 16px;

    path {
      fill: #fff;
    }

    &.success {
      background-color: var(--bs-green-dark);
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.timedOut,
    &.fail {
      background-color: var(--bs-red);
    }

    @keyframes spin { 
      100% { 
        transform: rotate(-360deg); 
      } 
    }

    &.signed,
    &.pending,
    &.sent {
      animation: spin 2s linear infinite;
      background-color: transparent;
    }
  }

  &-title {
    color: var(--bs-light);
    font-size: 14px;
    font-weight: 600;
  }

  .btn-close {
    align-items: center;
    background: transparent;
    color: var(--bs-light);
    display: flex;
    font-size: 18px;
    justify-content: center;
  }
}
