@import './src/theme/theme';

.nft-page-offer { 
  align-items: center;

  &-countdown {
    @include gradient-box($bg-color: var(--bs-color-2), $border-width: 1px, $border-radius: 30px);
    align-items: center;
    display: inline-block;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.35;
    pointer-events: none;
    
  
    @include media-breakpoint-up(md) {
      font-size: 14px;
    }

    &-inner {
      padding: 4px 12px;
    }
  }

  &-action {
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
  }
}
