@import '/src/theme/theme';

.nft-media {
  height: 100vh;
  position: sticky;
  top: 0;
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: calc(100% - $sidebar-width);
  }
}

.nft-media-background {
  background-color: var(--bs-body-bg);
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: absolute;
  top: 0;
  width: 100%;
}
