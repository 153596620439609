.price {
  display: flex;
  
  &-amount {
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    text-align: right;
  }

  &-token {
    font-size: 85%;
    font-weight: normal;
  }

  &-usd {
    color: var(--bs-silver);
    font-size: 12px;
    line-height: 12px;
    margin-top: 4px;
    text-align: right;
  }
}
