@import 'src/theme/theme';

.nft-video {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  max-height: 100%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
  z-index: 50;

  @include media-breakpoint-up(lg) {
    height: 100%;
    padding: 45px 90px;
    width: 100%;
  }

  .nft-video-player {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: center;
  }

  .video-js {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: center;
  }

  .vjs-tech {
    display: none;
  
    height: auto;
    max-height: calc(100vh - 90px);
    max-height: 100%;
    max-width: 100%;
    pointer-events: auto;
    position: relative;
    width: auto;
  }
}
