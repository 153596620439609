.admin-sidebar {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: fit-content;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;

  }

  &-content {
    align-items: center;
    background-color: rgb(255, 255, 0);
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    flex-direction: column;

    .btn-secondary {
      background-color: rgb(0, 0, 0);
      border-radius: 8px;
      color: rgb(255, 255, 0);
      padding: 10px;
      text-orientation: mixed;
      writing-mode: vertical-rl;

      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }
  }
}
