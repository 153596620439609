@import 'src/theme/theme';

.hero {
  &-description {
    color: var(--bs-silver);
    font-size: 16px;
    line-height: 1.2;
    margin-top: 20px;
    max-width: 553px;
    text-align: center;
  }
}
