@import '/src/theme/theme';

.details-countdown {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  
  @media (min-width: 376px) {
    align-items: flex-end;
  }

  &-title {
    color: var(--bs-silver);
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 8px;
  }

  &-label {
    color: var(--bs-silver);
    font-size: 12px;
    margin: 0;
  }
}
