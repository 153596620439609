@import './src/theme/theme';

.dropzone-field {
  border: 2px dashed var(--bs-color-5);
  border-radius: 4px;
  max-width: 100%;
  min-height: 436px;
  overflow: hidden;
  padding: 15px;
  text-align: center;
  width: 100%;

  &:hover {
    border: 2px dashed var(--bs-primary);
    cursor: pointer;
  }

  .preview & {
    border: 0;
    padding: 0;
    width: 468px;

    .btn {
      width: 100%;
    }
  }

  .invalid & {
    border-color: var(--bs-danger);
  }

  .dragging & {
    border-color: var(--bs-primary);
  }

  input {
    position: absolute;
    z-index: -1;
  }

  .btn {
    font-weight: 600;
  }
}
