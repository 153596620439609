.dropdown-traits {
  font-size: 14px;

  .dropdown-menu {
    border: 0;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    color: var(--bs-light);
    font-size: inherit;
    font-weight: 500;
    margin-top: -2px !important;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 0;
    width: 100%;
  }

  .selected-traits {
    background-color: #0f1420;
    padding: 8px;
  }

  .selected-trait-button {
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 85px;
    color: var(--bs-silver);
    display: inline-flex;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    line-height: 15px;
    margin: 4px 4px 4px 0;
    order: 0;
    padding: 4px 8px;

    svg {
      margin-left: 4px;
    }

    &:hover {
      color: var(--bs-primary);
      opacity: 0.8;
    }
  }

  .dropdown-item {
    align-items: center;
    color: #f2f5fb;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    gap: 4px;
    height: 60px;
    line-height: 17px;
    overflow: hidden;
    padding: 12px 16px;
    text-overflow: ellipsis;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover,
    &:active {
      background-color: var(--bs-color-4);
      color: inherit;
    }

    &.active {
      background-color: transparent;
      color: var(--bs-primary);

      &:hover,
      &:active {
        background-color: var(--bs-color-4);
        color: var(--bs-primary);
      }
    }
  }

  .dropdown-btn {
    align-items: center;
    background-color: var(--bs-color-1);
    border-radius: 0;
    border-top: 1px solid var(--bs-color-4);
    box-sizing: border-box;
    color: #f2f5fb;
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    justify-content: space-between;
    line-height: 20px;
    max-width: 100%;
    min-height: 39px;
    overflow: hidden;
    padding: 18px 16px;

    .filters-count {
      background-color: var(--bs-white-10);
      border-radius: 100px;
      color: var(--bs-silver);
      display: inline-block;
      font-size: 12px;
      font-weight: 500;
      padding: 0 8px;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus:active {
      background-color: var(--bs-color-3);
      border-color: var(--bs-color-4);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
      color: var(--bsi-silver);
    } 

    &:focus-visible {
      background: var(--bs-color-5);
      border-color: var(--bs-primary);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
      color: var(--bsi-silver);
      outline: none;
    }

    &.show {
      background-color: var(--bs-color-3);
      border-color: transparent;
      border-radius: 6px 6px 0 0;

      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      color: var(--bs-silver);
      margin-left: 12px;
    }

    &::after {
      content: none;
    }
  }

  &-name {
    align-items: center;
    display: flex;
    max-width: calc(100% - 90px);

    span {
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .dropdown-count {
    background-color: var(--bs-white-10);
    border-radius: 100px;
    color: var(--bs-silver);
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
  }

  &-icon {  
    margin-left: auto;
  }

  .clear-button {
    align-items: center;
    border-right: 1px solid var(--bs-color-4);
    display: flex;
    margin-top: 1px;
    position: absolute;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    
    &-btn {
      background: none;
      border: 0;
      color: var(--bs-silver);
      display: flex;
      font-size: 12px;
      font-weight: 500;
      margin-right: 3px;
      padding: 0;

    }
 
    &:hover {
      opacity: 0.8;
    }

    
  }
}
