@import './src/theme/theme';

.profile-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15.6px;
  text-align: right;

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }
  
  @include media-breakpoint-up(xl) {
    justify-content: end;
  }

  > * {
    margin-bottom: 16px;
  
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }  
  }

  .follow-buttons {
    .profile-followers:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    } 

    .profile-followers:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    } 

    &-separator {
      background-color: var(--bs-white-10);
      margin-left: -1px;
      width: 1px;
    }
  }
}
