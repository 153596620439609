@import '/src/theme/theme';

.top-bidder-text {
  align-items: center;
  background: rgba(var(--bs-green-dark-rgb), 0.05);
  border-radius: 4px;
  color: var(--bs-green-dark);
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  line-height: 1.38;
  padding: 8px 49px;
  text-align: center;
  white-space: nowrap;
  width: 100%;

  .img {
    height: 16px;
    width: 16px;
  }
}
