@import './src/theme/theme';

.tags-filter {
  &-button {
    align-items: center;
    background-color: var(--bs-color-2);
    border-radius: 100px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    display: flex;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 24px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }

    &:hover {
      background-color: var(--bs-color-3);
    }

    &::after {
      content: none;
    }

    &[disabled] {
      opacity: 0.7;
    }

    &-arrow {
      font-size: 10px;
      margin-left: 6px;

      @include media-breakpoint-up(md) {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}
