@import 'src/theme/theme';

.unfollow-button {
  border-width: 2px;
  color: var(--bs-primary) !important;
  display: flex;
  justify-content: center;
  padding: $btn-padding-y 18px;

  svg {
    color: inherit !important;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    color: var(--bs-red) !important;
  }

  &:focus-visible {
    color: var(--bs-red) !important;
  }
}
