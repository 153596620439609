.final-fee-header.accordion-button {
  border: 0;
  padding: 20px;

  svg {
    height: auto;
    width: 8px;
  }
}

.final-fee-header-label {
  font-size: 12px;
}
