@import 'src/theme/theme';

.nft-page-traits {
  margin-bottom: 24px;
  margin-left: -4px;
  margin-right: -4px;
  margin-top: -10px;


  > div {
    padding-left: 4px;
    padding-right: 4px;
  }  
}

.nft-page-trait-col {
  flex: 1;
  min-width: 50%;

  @media (min-width: 375px) {
    min-width: 33%;
  }
}
