.background-surface {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  width: 100%;

  &.blurred {
    filter: blur(6px);
  }
}
