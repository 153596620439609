@import 'src/theme/theme';

.featured-boxes {
  margin-bottom: 100px;
  position: relative;

  @include media-breakpoint-up(lg) {
    margin-bottom: 200px;
  }

  .row {
    --bs-gutter-x: 24px;
    justify-content: center;
  }

  &-title {
    font-family: $headings-font-family;
    font-size: 34px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 auto 40px;
    text-align: center;
    
    @include media-breakpoint-up(lg) {
      font-size: 40px;
      margin: 0 auto 80px;
    }

    span {
      background: $xspotlight-gradient;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
