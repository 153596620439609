@import './src/theme/theme';

$border-width: 3px;

.loading-spinner {
  &-inner {
    border: $border-width solid var(--bs-color-1);
    border-radius: 50%;
    height: 100px;
    position: relative;
    width: 100px;

    @include media-breakpoint-up(lg) {
      height: 150px;
      width: 150px;
    }
  }

  &-color {
    animation: spinner 0.75s linear infinite;
    border: $border-width solid var(--bs-primary);
    border-left-color: transparent;
    border-radius: 50%;
    border-top-color: transparent;
    height: calc(100% + #{$border-width * 2});
    left: -$border-width;
    position: absolute;
    top: -$border-width;
    width: calc(100% + #{$border-width * 2});
  }

  &-text {
    color: var(--bs-primary);
    font-size: 10px;

    @include media-breakpoint-up(lg) {
      font-size: 12px;
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    0% {
      transform: rotate(360deg);
    }
  }
}
