@import './theme';

button {
  color: inherit;
}

.btn {
  white-space: nowrap;

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }
}

%btn-primary {
  background: var(--bs-primary);
  border: 0 !important;
  box-shadow: 0 4px 8px rgba(0, 31, 156, 0.16) !important;
  color: var(--bs-dark);
  padding: #{$btn-padding-y + $btn-border-width} #{$btn-padding-x + $btn-border-width};

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background: var(--bs-primary-hover) !important;
    color: var(--bs-color-1);
  }

  &:focus-visible {
    background: var(--bs-primary-hover);
    color: var(--bs-light);
    outline: none;
  }

  &:disabled {
    background: var(--bs-primary);
    color: var(--bs-color-1);
  }

  &.btn-lg {
    padding: #{$btn-padding-y-lg + $btn-border-width} #{$btn-padding-x-lg - $btn-border-width};
  }
}

.btn-primary {
  @extend %btn-primary;
}

.btn-secondary {
  background-color: var(--bs-secondary);
  border-color: transparent;
  box-shadow: none !important;
  color: var(--bs-primary);
  text-decoration: none;

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background-color: var(--bs-color-4);
    border-color: transparent;
    color: var(--bs-primary);
  }

  &:focus-visible {
    background-color: var(--bs-color-4);
    border-color: transparent;
    outline: none;
  }

  &:disabled {
    background-color: var(--bs-secondary);
    border-color: transparent;
    color: var(--bs-primary);
  }

  span {
    margin-left: 10px;

    span {
      margin: 0;
    }
  }


  &.icon-right {
    span {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  &.no-icon {
    span {
      margin: 0;
    }
  }
}

.btn-outline-primary {
  border-width: 1px;
}

.btn-link {
  border: 0;
  box-shadow: none;
  color: var(--bs-link-color);
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: initial;

  &:hover {
    text-decoration: underline;
  }
}
