@import 'src/theme/theme';

$offset: 12px;

.asset-card-badges {
  left: $offset;
  position: absolute;
  right: $offset;
  top: $offset;
  z-index: 2;
}
