@import '/src/theme/theme';

.featured-collection {
  border-bottom: 1px solid var(--bs-white-20);
  border-top: 1px solid var(--bs-white-20);
  margin-bottom: 16px;
  padding: 26px 0 5px;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-bottom: 32px;
    padding: 40px 0 20px;
  }

  &-bg {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    img {
      filter: blur(50px);
      height: calc(100% + 100px);
      left: -50px;
      max-width: initial;
      object-fit: cover;
      position: relative;
      top: -50px;
      width: calc(100% + 100px);
    }

    &::before {
      background: rgba(var(--bs-body-bg-rgb), 0.85);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

  }

  &-link {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &-header {
    align-items: center;
    display: flex;
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      margin-bottom: 60px;
    }
  }

  &-icon {
    border: 6px solid rgba(var(--bs-body-bg-rgb), 0.25);
    border-radius: 80px;
    flex: 0 0 70px;
    height: 70px;
    margin-right: 16px;
    width: 70px;

    @include media-breakpoint-up(md) {
      border-width: 8px;
      flex: 0 0 96px;
      height: 96px;
      width: 96px;
    }

    img {
      border-radius: 100px;
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  &-title {
    align-items: center;
    display: flex;
    font-family: $headings-font-family;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 5px;

    @include media-breakpoint-up(md) {
      font-size: 36px;
      margin-bottom: 10px;
    }

    .svg-inline--fa {
      font-size: 70%;
      margin-left: 0.6em;
    }
  }

  &-description {
    font-size: 13px;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }
}
