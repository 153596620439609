@import './src/theme/theme';

.asset-card-details {
  padding: 16px;
  pointer-events: none;
  position: relative;

  @include media-breakpoint-up(md) {
    padding: 24px 18px;
  }

  .user-image {
    border: 0;
  }

  .user-info {
    font-size: 14px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }

  a {
    text-decoration: none;
  }
}
