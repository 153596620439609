.facet-button {
  align-items: center;
  background-color: var(--bs-color-3);
  border: 0;
  box-sizing: border-box;
  color: var(--bs-light);
  display: flex;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 8px 8px 16px;
  transition-property: background-color;

  &:disabled {
    cursor: pointer;
    pointer-events: auto;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background-color: var(--bs-color-4);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    color: var(--bs-light);
  }

  &:focus-visible {
    background-color: var(--bs-color-5);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    outline: none;
  }

  &.active {
    background: linear-gradient(260.76deg, var(--bs-primary) 0%, var(--bs-primary-hover) 100%);
    color: var(--bs-dark);

    &:hover,
    &:active,
    &:focus,
    &:focus:active {
      background: linear-gradient(260.76deg, var(--bs-primary-hover) 0%, var(--bs-primary-hover) 100%);
      color: var(--bs-dark);
    }

    &:focus-visible {
      background: linear-gradient(260.76deg, var(--bs-primary) 0%, var(--bs-primary) 100%);
    }

    .facet-button-count {
      background-color: var(--bs-white-20);
      color: var(--bs-dark);
    }
  }

  &-image {
    font-size: 14px;
    line-height: 1.35;
    margin-right: 8px;
    width: 15px;
  }

  &-text {
    display: inline-block;
  }

  &-count {
    background-color: var(--bs-white-10);
    border-radius: 100px;
    color: var(--bs-silver);
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
  }

  &-close {
    align-items: center;
    display: flex;
    font-weight: 500;
    padding: 4px 8px;
  }
}
