@import 'src/theme/theme';

.user-info {
  &-title {
    color: var(--bs-silver);
    flex-grow: 1;
    font-family: $headings-font-family;
    font-size: inherit;
    font-size: 85%;
    margin-left: 10px;
    text-align: right;
  }
}
