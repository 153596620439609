@import 'src/theme/theme';

.like-button {
  border-width: 0;
  color: var(--bs-light);
  display: flex;
  font-weight: 500;
  justify-content: center;

  svg {
    color: inherit !important;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background-color: rgba(var(--bs-pink-rgb), 0.15);
    color: var(--bs-pink) !important;
  }

  &:focus-visible {
    background-color: rgba(var(--bs-pink-rgb), 0.2);
    color: var(--bs-pink) !important;
  }
}
