@import './src/theme/theme';

.overview-section {
  &-subtitle,
  &-description {
    color: var(--bs-silver);
  }


  &-box {
    color: var(--bs-silver);
    max-width: 125px;
    width: 80%;

    @include media-breakpoint-up(md) {
      max-width: 175px;
    }
  }

  &-box-image {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;

    &-wrapper {
      padding-top: 200%;
      position: relative;
    }


    

  }
}
