.quantity-form-field {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input {
    appearance: textfield;
  }
}



