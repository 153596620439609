@import 'src/theme/theme';

.auctions-tabs {
  .nav-tabs {
    padding-bottom: 16px;
  }
}

.auctions-tabs-header {
  margin-bottom: 27px;
  margin-left: -$modal-content-padding;
  margin-right: -$modal-content-padding;
  overflow: hidden;
  padding-left: $modal-content-padding;
  padding-right: $modal-content-padding;

  @include media-breakpoint-down(md) {
    margin-left: -$modal-content-padding-mobile;
    margin-right: -$modal-content-padding-mobile;
    padding-left: $modal-content-padding-mobile;
    padding-right: $modal-content-padding-mobile;
  }

  .swiper {
    overflow: visible;

    &-wrapper {
      padding-bottom: 16px;
      padding-top: 10px;
    }
  }

  .swiper-slide {
    width: auto !important;
  }
}
