@import './src/theme/theme';

.my-asset-card {
  &-title {
    flex: 1 0 auto;
    font-family: $headings-font-family;
    font-size: 16px;
    font-weight: 600;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    @include media-breakpoint-up(sm) {
      flex: 1 0 0%;
      font-size: 18px;
      width: auto;
      
    }
  }
}
