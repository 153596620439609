@import '/src/theme/theme';

.nft-page {
  
  @include media-breakpoint-up(lg) {
    height: 100%;
    left: 0;
    overflow: auto;
    overscroll-behavior: contain;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
