.column-section {
  &-content {
    color: var(--bs-silver);

    h3 {
      color: var(--bs-light);
      margin-bottom: 10px;
    }
  }

  &-image img {
    border-radius: 6px;
  }
}
