.traits-button {
  align-items: center;
  background-color: var(--bs-color-3);
  border: 0;
  box-sizing: border-box;
  color: var(--bs-light);
  display: flex;
  font-size: 16px;
  font-weight: 400;
  min-height: 40px;
  padding: 8px 8px 8px 16px;


  &:disabled {
    cursor: pointer;
    pointer-events: auto;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background-color: var(--bs-color-4);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    color: var(--bs-light);
  }

  &:focus-visible {
    background-color: var(--bs-color-5);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    outline: none;
  }

  &-text {
    display: inline-block;
    margin: 0 7px;
  }

  &-count {
    background-color: var(--bs-white-10);
    border-radius: 100px;
    color: var(--bs-silver);
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
  }

  svg {
    color: var(--bs-silver);
  }
}
