.subdomain-footer-links {
  color: var(--bs-silver);
  font-size: 14px;
  margin-bottom: 32px;
  margin-top: -16px;
  
  a {
    color: var(--bs-silver);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    margin: 0 10px;
  }

}
