.dropzone-field-preview-image {
  border-radius: 4px;
  margin-bottom: 29px;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  width: 100%;

  img {
    height: 100%;
    left: 0;
    object-fit: contain;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
  }

  svg {
    height: 150px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 150px;
  }
}
