@import 'src/theme/theme';

.holoride-campaign {
  margin-bottom: 50px;
 
  @include media-breakpoint-up(lg) {
    margin-bottom: 100px;
  }

  &-media {
    border-radius: 6px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      max-height: 500px;
      max-width: 500px;
    }

    video {
      display: block;
      max-width: 100%;
    }
  }
}
