.holoride-campaign-buy {
  .btn {
    margin-bottom: 16px;
    width: 100%;
  }

  &-label {
    color: var(--bs-silver);
    margin-bottom: 5px;
  }
}
