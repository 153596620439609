@import './src/theme/theme';

@keyframes moving {
  0% {
    background-position: center 0%;
  }

  100% {
    background-position: center 100%;
  }
}

.ghost-creator-card {
  animation: moving 1.5s linear infinite alternate;
  background: linear-gradient(180deg, var(--bs-body-bg) 0%, var(--bs-color-1) 50%, var(--bs-body-bg) 100%);
  border: $card-border-width solid var(--bs-card-border-color);
  border-radius: $card-border-radius;
  
  &-inner {
    padding-top: 140%;
  }
}
