.subdomain-video-section {
  &-video {
    padding-top: 56.25%;
    position: relative;


    .player {
      position: absolute;
      top: 0;
    }
  }
}
