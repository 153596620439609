.nft-page-trait {
  background-color: var(--bs-color-3);
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 12px;
  text-align: center;

  &-type {
    color: var(--bs-silver);
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-value {
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
