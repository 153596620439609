@import './src/theme/theme';

.disconnect-link {
  align-items: center;
  color: var(--bs-white);
  display: flex;

  @include media-breakpoint-down(md) {
    font-size: 12px;
    padding: 10px 12px;

    &:focus,
    &:active,
    &:active:focus {
      padding: 10px 12px;
    }
  }
  
  &:hover {
    color: var(--bs-white);
  }

  svg {
    margin-bottom: 2px;
    @include media-breakpoint-down(md) {
      margin-right: 0;
    }

    path {
      fill: var(--bs-white);
    }
  }
}
