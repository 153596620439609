@import 'src/theme/theme';

.subdomain-campaign {
  $self: &;
  
  .btn {
    span {
      margin-right: 10px;
    }
  }

  &-image {
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      max-width: 400px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 500px;
    }
    
    &-inner {
      padding-top: 100%;
    }
    
    &-wrapper {
      border-radius: 6px;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    &-link {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;

      &:focus-visible {
        border-radius: $card-border-radius;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
        outline: 2px solid var(--bs-primary);
      }
    }

    &-img,
    &-blurred {
      height: 100%;
      left: 0;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      transition: all 0.3s ease-in-out;
      width: 100%;
    }
    
    &-blurred {
      filter: blur(6px);
      z-index: -1;
    }

    &:hover {
      .subdomain-campaign-image-img {
        transform: scale(1.05);
      }
    }
  }
  
  &-subtitle {
    margin-bottom: 50px;
    position: relative;
    
    &::after {
      background: var(--bs-primary);
      border-radius: 6px;
      bottom: -15px;
      content: '';
      height: 4px;
      left: 0;
      position: absolute;
      width: 30%;
    }
  }
  
  &-description {
    margin-bottom: 40px;
  }
}
