/* stylelint-disable selector-class-pattern, selector-max-compound-selectors, selector-no-qualifying-type */
.date-time-field {
  .form-control-icon {
    z-index: 2;
  }

  .rdtPicker {
    background-color: var(--bs-card-bg);
    border-color: var(--bs-card-border-color);

    th {
      border-color: var(--bs-card-border-color);
    }

    td.rdtDay:hover,
    td.rdtHour:hover,
    td.rdtMinute:hover,
    td.rdtSecond:hover,
    .rdtTimeToggle:hover,
    .rdtBtn:hover,
    thead tr:first-of-type th:hover {
      background-color: var(--bs-card-border-color);
    }

    td.rdtActive,
    td.rdtActive:hover {
      background-color: var(--bs-primary);
    }
  }
}
