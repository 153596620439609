@import 'src/theme/theme';

/* stylelint-disable */
.CircularProgressbar-path {
  stroke: var(--bs-primary) !important;
}
/* stylelint-enable */

.initial {
  &-heading {
    font-size: 21px;
    font-weight: bold;
  }

  &-bold {
    font-weight: bold;
  }

  &-progress-container {
    font-size: 12px;
    height: 50px;
    width: 50px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
      height: 65px;
      width: 65px;
    }
  }
}

.launch-feed-button {
  @include media-breakpoint-down(md) {
    font-size: 12px;
    padding: 13px 14px;
  }
}
