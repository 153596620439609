.dropdown-menu {
  border: 0;
  border-radius: 6px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  color: var(--bs-light);
  font-size: inherit;
  font-weight: 500;
  padding: 6px 0;
}

.dropdown-item {
  font-weight: 500;
  padding: 8px 22px;

  &:hover,
  &:active {
    background-color: var(--bs-color-4);
    color: inherit;
  }

  &.active {
    background-color: transparent;
    color: var(--bs-primary);

    &:hover,
    &:active {
      background-color: var(--bs-color-4);
      color: var(--bs-primary);
    }
  }
}

.dropdown-btn {
  align-items: center;
  background-color: var(--bs-color-3);
  border-color: var(--bs-color-3);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  color: var(--bsi-silver);
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 12px 18px;

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background-color: var(--bs-color-4);
    border-color: var(--bs-color-4);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    color: var(--bsi-silver);
  }

  &:focus-visible {
    background: var(--bs-color-5);
    border-color: var(--bs-primary);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    color: var(--bsi-silver);
    outline: none;
  }

  svg {
    color: var(--bs-silver);
    margin-left: 12px;
  }

  &::after {
    content: none;
  }
}
