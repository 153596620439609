@import './src/theme/theme';

.profile-followers {
  align-items: center;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  line-height: $btn-line-height;
  padding: $btn-padding-y 17px;


  &:not(.btn) {
    background: rgba(var(--bs-silver-rgb), 0.1);
  }

  &-label {
    color: var(--bs-silver);
    font-weight: 400;
    margin-left: 6px;
  }

  span {
    margin: 0;
  }

  .profile-followers-count {
    font-weight: 500;
    margin-right: 4px;
  }
}
