@import 'src/theme/theme';

.metaspaces-carousel {
  --swiper-theme-color: var(--bs-light);
  --swiper-pagination-bullet-inactive-color: var(--bs-color-3);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-width: 40px;
  --swiper-pagination-bullet-height: 4px;
  --swiper-navigation-size: 32px;

  left: calc((100% - 100vw)/2);
  margin-top: -50px;
  max-width: 100vw;
  padding: 50px 0;
  position: relative;
  width: 100vw;

  @include media-breakpoint-up(sm) {
    left: max(-50px, calc((100% - 100vw)/2));
    width: calc(100% + 100px);
  }
  
  
  @include media-breakpoint-up(md) {
    left: max(-100px, calc((100% - 100vw)/2));
    width: calc(100% + 200px);
  }
  
  .swiper-slide {
    background: linear-gradient(90deg, #fbca1c 0%, #ffefb7 50%, #fff 100%) border-box;
    border: 2px solid transparent;
    border-radius: 6px;
    position: relative;
    width: calc(100% - 48px);

    @include media-breakpoint-up(sm) {
      width: calc(100% - 100px);
    }

    @include media-breakpoint-up(md) {
      width: calc(100% - 300px);
    }

    
    &::after {
      background: linear-gradient(to left, #61dada, #7eaaff, #a67cff);
      border-radius: 6px;
      content: '';
      filter: blur(20px);
      height: 100%;
      left: 0;
      opacity: 0.3;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }

  .swiper-pagination-bullet {
    border-radius: 40px;
  }

  .swiper-gradient {
    &-start,
    &-end {
      background: linear-gradient(90deg, var(--bs-body-bg) 0%, rgba(0, 0, 0, 0) 100%);
      display: none;
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 120px;
      z-index: 1;

      @include media-breakpoint-up(sm) {
        display: block;
        width: 60px;
      }

      @include media-breakpoint-up(md) {
        width: 120px;
      }
    }

    &-end {
      background: linear-gradient(-90deg, var(--bs-body-bg) 0%, rgba(0, 0, 0, 0) 100%);
      left: auto;
      right: 0;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 5px;

    @include media-breakpoint-up(md) {
      right: 50px;
    }
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 5px;

    @include media-breakpoint-up(md) {
      left: 50px;
    }
  }
  
  &-item {
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 650px;
    justify-content: flex-end;
    overflow: hidden;
    padding: 16px;
    position: relative;
    
    @include media-breakpoint-up(md) {
      padding: 48px;
    }
    
    &-gradient {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--bs-body-bg) 100%);
      bottom: 0;
      height: 400px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    .btn {
      margin-top: 16px;
      width: 100%;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        width: auto;
      }
    }
    
    &-title {
      font-family: $headings-font-family;
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 16px;
      
      @include media-breakpoint-up(md) {
        font-size: 36px;
        margin-bottom: 24px;
      }
    }
    
    &-description {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        margin-bottom: 0;
      }
    }
    
    &-text {
      color: var(--bs-light);
    }
    
    &-logo {
      margin-bottom: 16px;
      max-height: 48px;
      width: 185.38px;
      
      @include media-breakpoint-up(md) {
        margin-bottom: 24px;
      }
    }
  }  
}
