@import './src/theme/theme';

.search-overlay {
  align-items: center;
  box-shadow: 0 10px 24px rgba(90, 97, 105, 0.08);
  min-height: $header-height-mobile;

  @include media-breakpoint-up(md) {
    box-shadow: none;
    min-height: $header-height;
  }


  input {
    height: 41px;

    @include media-breakpoint-up(md) {
      height: 48px;
    }

    &:focus {
      box-shadow: none;
    }
  }
}
