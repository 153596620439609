.sidebar-mywallet {
  .herotag {
    margin-top: -25px;
  }

  .username {
    display: flex;
    font-size: 18px;
    justify-content: center;
    margin-bottom: 49px;
  }
}
