.user-info {
  &-right {
    min-width: 0;
  }
  
  &.disabled {
    pointer-events: none;
  }

  a {
    pointer-events: auto;
    text-decoration: none;
  }

  .user-image {
    border: 0;
    flex: 0 0 1.76em;
    margin-right: 0.4em;
    width: 1.76em;
  }


  &.size-big {
    .user-image {
      margin-right: 0.9em;
      width: 2.85em;
    }
  }

  .explorer-link {
    font-size: inherit;
    margin-left: 0.5em;
  }
}
