.nft-tag-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: -5px;

  .asset-tag {
    margin-bottom: 5px;
    margin-left: 5px;
  }
}
