@import 'src/theme/theme';

.featured-twitter {
  margin-bottom: 100px;
  position: relative;

  @include media-breakpoint-up(lg) {
    margin-bottom: 200px;
  }

  .row {
    justify-content: center;
  }
  
  &-image {
    max-width: 100%;
  }

  &-text {
    font-family: $headings-font-family;
    font-size: 32px;
    font-weight: 100;
    line-height: 1.2;
    margin: 0 auto 40px;

    @include media-breakpoint-up(lg) {
      font-size: 40px;
      margin: 0 auto 80px;
    }
  }
}
