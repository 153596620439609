@import './src/theme/theme';

.collection-stats {
  border: 1px solid var(--bs-color-3);
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  justify-content: space-evenly;
  margin-bottom: 32px;
  padding-bottom: 4px;
  padding-top: 20px;
  width: 100%;

  @include media-breakpoint-down(md) {
    padding-top: 16px;
  }
}
