@import 'nft-page/mixins';

.nft-page-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .nav-tabs {
    border: 0;
    border-bottom: 1px solid var(--bs-color-3);
    
    .nav-link {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid transparent;
      color: var(--bs-silver);
      flex-grow: 1;
      font-size: 16px;
      font-weight: 500;
      padding: 11px 20px;

      &.active {
        border-bottom: 2px solid var(--bs-primary);
        color: var(--bs-primary);
      }
    }
  }

  .tab-content {
    @include nft-page-content-padding-x();
    background-color: var(--bs-color-1);
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
    flex-grow: 1;
    padding-bottom: 40px;
    padding-top: 24px;
  }
}
