@import 'src/theme/theme';

.btn-button {
  background: var(--bs-color-2);
  border: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04) !important;
  color: var(--bs-light) !important;

  span {
    margin-left: 10px;
    white-space: nowrap;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background: var(--bs-color-3);
  }

  &:focus-visible {
    background: var(--bs-color-4);
  }

  &.icon-right {
    span {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  &.no-icon {
    span {
      margin-left: 0;
    }
  }
}
