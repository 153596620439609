@import 'src/theme/theme';

.featured-page-visual {
  font-size: 1px;
  position: absolute;
  width: 0.3em;
  
  @include media-breakpoint-up(sm) {
    width: 0.4em;
  }
  
  @include media-breakpoint-up(md) {
    width: 0.5em;
  }
  
  @include media-breakpoint-up(lg) {
    width: 0.7em;
  }
  
  @include media-breakpoint-up(xl) {
    width: 1em;
  }
  
  &s {
    height: 100%;
    left: 50%;
    margin: 0 auto;
    max-width: 1600px;
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
  }
  
  &-0 {
    left: 0;
    top: 70%;
    transform: translateY(-50%) translateX(-45%);
  }
  
  &-1 {
    left: 90%;
    transform: translateY(-50%) translateX(-45%);
  }
  
  &-2 {
    left: 80%;
    transform: translateY(-50%) translateX(-50%);
  }
}
