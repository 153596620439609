@import './src/theme/theme';

.quantity-field-button {
  color: var(--bs-primary);
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
