@import 'src/theme/theme';

.my-asset-card {
  background-color: var(--bs-card-bg);
  border: $card-border-width solid var(--bs-card-border-color);
  border-radius: $card-border-radius;
  max-width: 100%;
  padding: 4px;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 100%;

  &:hover {
    background-color: var(--bs-card-bg-hover);
    border-color: var(--bs-card-hover-border-color);

    .asset-card-shadow {
      box-shadow: $card-box-shadow-hover;
    }
  }

  &-infos {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 8px 16px;
    width: calc(100% - 100px);
  }
}
