@font-face {
  font-display: swap;
  font-family: 'Roobert';
  font-style: normal;
  font-weight: 400;
  src: local('Roobert-Regular'), url('./Roobert-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Roobert';
  font-style: normal;
  font-weight: 600;
  src: local('Roobert-SemiBold'), url('./Roobert-SemiBold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Roobert';
  font-style: normal;
  font-weight: 700;
  src: local('Roobert-Bold'), url('./Roobert-Bold.woff') format('woff');
}
