@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';


// new colors
$primary: #fbca1c;
$secondary: #212121;

$body-bg: #010101;
$link-color: $primary;

$color-1: #0b0b0b;
$color-2: #161616; // grey highlight
$color-3: $secondary;
$color-4: #2c2c2c;
$color-5: #373737;


$green-dark: #00c8b0;
$green-light: #91ffa2;
$red: #e51d13;
$yellow: #fac230;
$orange: #ee6220;
$pink: #e4179e; 
$silver: #8e9396;
$text-muted: $silver;
$black: #000;
$white: #fff;

$green-highlight: rgba(0, 200, 176, 0.1);
$white-10: rgba(255, 255, 255, 0.1);
$white-20: rgba(255, 255, 255, 0.2);

$custom-theme-colors: map-merge($theme-colors, (
  'primary': $primary,
  'success': $green-dark,
  'warning': $yellow,
  'secondary': $secondary,
  'danger': $red,
  'red': $red,
  'light': #f2f5fb,
  'silver': $silver,
  'dark':  $black,
));

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');

//cards
$card-bg: $color-2;
$card-bg-hover: $color-3;
$card-border-color: $color-2;
$card-hover-border-color: $color-2;


$maiar-colors: (
  'body-bg': $body-bg,
  'link-color': $link-color,
  
  'color-1': $color-1,
  'color-2': $color-2,
  'color-3': $color-3,
  'color-4': $color-4,
  'color-5': $color-5,
  
  'green-dark': $green-dark,
  'green-light': $green-light,
  'red': $red,
  'yellow': $yellow,
  'orange': $orange,
  'pink': $pink,
  'silver': $silver,
  'black': $black,
  'white': $white,

  'green-highlight': $green-highlight,
  'white-10': $white-10,
  'white-20': $white-20,

  'card-bg': $card-bg,
  'card-bg-hover': $card-bg-hover,
  'card-border-color': $card-border-color,
  'card-hover-border-color': $card-hover-border-color,
);



:root {
  --bs-primary-hover: #{lighten($primary, 10)};

  @each $color, $value in $maiar-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  $maiar-colors-rgb: map-loop($maiar-colors, to-rgb, '$value');

  @each $color, $value in $maiar-colors-rgb {
    --#{$variable-prefix}#{$color}-rgb: #{$value};
  }
}


@import './bootstrap/colors';
@import './bootstrap/variables';

