.dropdown-tokens {
  font-size: 14px;

  .dropdown-menu {
    border: 0;
    border-radius: 6px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    color: var(--bs-light);
    font-size: inherit;
    font-weight: 500;
    max-height: 250px;
    overflow: auto;
    padding: 6px 0;
  }

  .dropdown-item {
    font-size: inherit;
    font-weight: 500;
    padding: 8px 22px;

    &:hover,
    &:active {
      background-color: var(--bs-color-4);
      color: inherit;
    }

    &.active {
      background-color: transparent;
      color: var(--bs-primary);

      &:hover,
      &:active {
        background-color: var(--bs-color-4);
        color: var(--bs-primary);
      }
    }
  }

  .dropdown-btn {
    align-items: center;
    background-color: var(--bs-color-3);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    display: flex;
    font-size: inherit;
    font-weight: 500;
    justify-content: space-between;
    min-height: 39px;
    padding: 8px 16px;

    &:hover,
    &:active,
    &:focus,
    &:focus:active {
      background-color: var(--bs-color-4);
      border-color: var(--bs-color-4);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
      color: var(--bsi-silver);
    }

    &:focus-visible {
      background: var(--bs-color-5);
      border-color: var(--bs-primary);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
      color: var(--bsi-silver);
      outline: none;
    }

    svg {
      color: var(--bs-silver);
      margin-left: 12px;
    }

    &::after {
      content: none;
    }
  }

  &.active {
    .dropdown-btn {
      background: linear-gradient(260.76deg, var(--bs-primary) 0%, var(--bs-primary-hover) 100%);

      &:hover,
      &:active,
      &:focus,
      &:focus:active {
        background: linear-gradient(260.76deg, var(--bs-primary-hover) 0%, var(--bs-primary-hover) 100%);
      }

      &:focus-visible {
        background: linear-gradient(260.76deg, var(--bs-primary) 0%, var(--bs-primary) 100%);
      }

      .dropdown-count {
        color: var(--bs-white);
      }
    }
  }
  
  .dropdown-count {
    background-color: var(--bs-white-10);
    border-radius: 100px;
    color: var(--bs-silver);
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
  }

  &-icon {
    margin-left: auto;
  }
}
