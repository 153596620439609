.royalties-information-item {
  color: var(--bs-silver);
  font-size: 14px;

  &-title {
    color: var(--bs-light);
    font-size: 14px;
    font-weight: bold;
  }

  &-text {
    font-weight: bold;
  }
}
