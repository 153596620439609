.buy-campaign-form {
  &-tier {
    label {
      margin-bottom: 16px;
    }
  }

  &-total {
    background-color: rgba(64, 117, 255, 0.05);
    border-radius: 10px;
    margin-left: -16px;
    margin-right: -16px;
    padding: 16px;
    width: calc(100% + 32px);
  }
}
