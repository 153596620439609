@import 'src/theme/theme';

.subdomain-collection {
  $self: &;
  justify-content: space-between;
  margin-bottom: 40px;
  max-width: 100%;
  min-height: 517.84px;
  position: relative;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 100px;

    @include media-breakpoint-up(md) {
      margin-bottom: 160px;
    }
  }
    
  &-content {
    padding-bottom: 20px;
    padding-top: 20px;

    @include media-breakpoint-up(md) {
      padding-bottom: 40px;
      padding-top: 40px;
    }
  }
  
  .btn {
    @include media-breakpoint-down(md) {
      font-size: 12px;
      margin-left: 0.6rem;
      padding: 13px 14px;
    }

    span {
      margin-right: 10px;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &-title {
    font-family: $headings-font-family;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }

  &-description {
    color: var(--bs-silver);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      max-width: 85%;
    }
  }
  
  &-link {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;

    &:focus-visible {
      border-radius: $card-border-radius;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
      outline: 2px solid var(--bs-primary);
    }
  }
  
  &-images {
    --bs-gutter: 4px;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    margin: -2px;
    position: relative;
    width: 100%;

    &:hover {  
      > #{$self}-image img {
        transform: scale(1.05);
      }
    }
  }

  &-blurry {
    display: flex;
    filter: blur(50px);
    flex: 0 0 auto;
    flex-wrap: wrap;
    height: 100%;
    left: 0;
    opacity: 0.4;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  
  &-image {
    background: var(--bs-gray);
    margin: calc(var(--bs-gutter) / 2);
    overflow: hidden;
    width: calc(50% - var(--bs-gutter));

    @include media-breakpoint-up(md) {
      width: calc(25% - var(--bs-gutter));
    }

    img {
      max-width: 100%;
      transition: all 0.3s ease-in-out;
      will-change: transform;
    }

    &:nth-child(1) {
      border-top-left-radius: 6px;
    }

    &:nth-child(2) {
      border-top-right-radius: 6px;

      @include media-breakpoint-up(md) {
        border-radius: 0;
      }
    }

    &:nth-child(4) {
      @include media-breakpoint-up(md) {
        border-top-right-radius: 6px;
      }
    }

    &:nth-child(5) {
      @include media-breakpoint-up(md) {
        border-bottom-left-radius: 6px;
      }
    }

    &:nth-child(7) {
      border-bottom-left-radius: 6px;

      @include media-breakpoint-up(md) {
        border-radius: 0;
      }
    }

    &:nth-child(8) {
      border-bottom-right-radius: 6px;
    }
  }
  
}
