@import 'src/theme/theme';

.auctions-filters {
  display: flex;
  flex-direction: column-reverse;

  @include  media-breakpoint-up(xl) {
    align-items: center;
    flex-direction: row;
  }

  .swiper {
    overflow: visible;
    pointer-events: initial;
    width: 100%;

    @include  media-breakpoint-up(xl) {
      margin-right: -20px;
      width: auto;
    }

    &-slide {
      width: auto !important;

      &:empty {
        margin: 0 !important;
      }
    }
  }


  &-sorting {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    margin-bottom: calc(-500px + 24px);
    overflow: hidden;
    padding-bottom: 500px;
    pointer-events: none;

    @include  media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    .swiper-slide {
      width: auto !important;
    }
  }

  .price-range-token {
    margin-bottom: 24px;
  }
}
