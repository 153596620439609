@import './src/theme/theme';

.connect-link {
  display: flex;

  @include media-breakpoint-down(md) {
    font-size: 12px;
    padding: 10px 12px;

    &:focus,
    &:active,
    &:active:focus {
      padding: 10px 12px;
    }
  }

  svg {
    margin-right: 8px;

    @include media-breakpoint-down(md) {
      margin-right: 0;
    }
  }
}
