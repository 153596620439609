@use 'sass:math';
@import '../../../../theme/theme';

.explore-nfts-filter {
  .swiper {
    margin-left: 0;
    width: 100%;
  }

  .swiper-wrapper {
    padding: 0 0 6px;
  }

  .swiper-slide {
    width: auto !important;
  }

  .facets-selector,
  .price-range-token {
    margin-bottom: 24px;
  }
}
