@import './src/theme/theme';

.action-link {
  color: var(--bs-primary);
  text-decoration: none;

  &-disabled {
    color: var(--bs-silver);
    pointer-events: none;
    text-decoration: none;
  }
}
