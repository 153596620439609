.campaign-status {
  font-size: 16px;

  &-icon svg {
    height: auto;
    margin-right: 11px;
  }

  &-label {
    color: var(--bs-silver);
    font-size: 76%;
  }

  &-text {
    font-weight: 600;
  }

  &-time {
    color: var(--bs-primary);

    &.running {
      color: var(--bs-green-dark);
    }

    &.ended {
      color: var(--bs-orange);
    }
  }

  .duration-label {
    font-weight: 600;

    &-digits {
      font-weight: 500;
    }
  }
}
