@import './src/theme/theme';

.data-card-header {
  &-title-small {
    font-size: 21px !important;
  }

  &-title {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-shrink: 1;
    font-family: $headings-font-family;
    font-size: 16px;
    font-weight: 700;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }


    &-inner {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-prefix {
      display: none;
      @include media-breakpoint-up(md) {
        display: inline;
      }
    }

    .verified-badge {
      margin-left: 0.3em;
    }
  }
}
