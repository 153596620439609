.nft-current-bid-egld-price {
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
}

.nft-current-bid-egld-token {
  font-size: 80%;
  font-weight: normal;
}

.nft-current-bid-price {
  color: var(--bs-silver);
  font-size: 12px;
  line-height: 14px;
}
