.nft-page-details-uris {
  color: var(--bs-primary);
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;

  &-disabled {
    color: var(--bs-gray);
    display: block;
    overflow: hidden;
    pointer-events: none;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
  }
}
