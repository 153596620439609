.username {
  align-items: center;
  display: flex;
  max-width: 100%;
  min-width: 0;

  &-link {
    display: flex;
    font-size: inherit;
    line-height: inherit;
    max-width: 100%;
    min-width: 0;
    text-decoration: none;

    &.disabled {
      pointer-events: none;
    }
  }

  &-at {
    color: var(--bs-pink);
    margin: 0;
  }

  &-username {
    color: var(--bs-light);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.color-primary {
    .username-username {
      color: var(--bs-primary);

      &:hover {
        color: var(--bs-primary-hover);
      }
    }
  }

  .explorer-link {
    font-size: 0.85em;
    margin-left: 0.5em;
  }

  .subdomain-layout &.color-primary .username-username {
    color: var(--bs-light);
  }
}
