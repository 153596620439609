//gradient
$xspotlight-gradient: linear-gradient(270deg, #fbca1c 0%, #8533ff 100%);

// accordion
$accordion-border-color: var(--bs-light);
$accordion-color: var(--bs-light);
$accordion-bg: var(--bs-color-1);
$accordion-button-bg: var(--bs-color-2);
$accordion-button-active-bg: var(--bs-color-2);
$accordion-button-active-color: var(--bs-light);
$accordion-button-focus-border-color: var(--bs-card-border-color);
$accordion-button-focus-box-shadow: var(--bs-light);

// inputs
$component-active-bg: $primary;
$input-color: var(--bs-light);
$input-bg: transparent;
$input-focus-bg: transparent;
$input-focus-color: var(--bs-light);
$input-placeholder-color: var(--bs-silver);
$input-border-color: var(--bs-color-3);
$input-focus-border-color: var(--bs-color-5);
$input-disabled-bg: transparent;
$form-check-input-bg: $input-bg;
$form-check-input-focus-box-shadow: none;
$form-check-input-border: 1px solid var(--bs-white);
$form-check-input-focus-border: var(--bs-primary);
$form-check-input-checked-bg-color: var(--bs-primary);
$form-check-input-checked-border-color: var(--bs-primary);

// select
$form-select-color: var(--bs-light);
$form-select-bg: transparent;
$form-select-border-color: var(--bs-color-3);
$form-select-focus-border-color: var(--bs-color-5);
$form-select-indicator-color: var(--bs-light);

// labels
$form-label-color: var(--bs-light);

// dropdown
$dropdown-bg: var(--bs-color-3);
$dropdown-border-color: var(--bs-color-3);
$dropdown-link-color: var(--bs-light);
$dropdown-link-hover-color: var(--bs-light);
$dropdown-link-hover-bg: var(--bs-color-4);

// tooltips
$tooltip-bg: var(--bs-color-3);
$tooltip-color: var(--bs-light);
