@import './src/theme/theme';

@keyframes moving {
  0% {
    background-position: center 0%;
  }

  100% {
    background-position: center 100%;
  }
}

.ghost-collection-card {
  animation: moving 1.5s linear infinite alternate;
  background: linear-gradient(180deg, var(--bs-body-bg) 0%, var(--bs-color-1) 50%, var(--bs-body-bg) 100%);
  background-size: 100% 200%;
  border: $card-border-width solid var(--bs-card-border-color);
  border-radius: $card-border-radius;
  padding-top: 100%;
  padding-top: 129.8%;
  position: relative;
}
