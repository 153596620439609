@import 'src/theme/theme';

.tab-header {
  margin-bottom: 32px;

  &-filters {
    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }
}
