.holoride-action-field {
  display: flex;
  justify-content: space-between;

  &-label {
    color: var(--bs-silver);
  }

  &-value {
    font-weight: 600;
  }
}
