@import 'nft-page/mixins';
@import './src/theme/theme';

.details-editions {
  @include gradient-box($bg-color: var(--bs-color-2), $border-width: 1px);
  left: 50%;
  position: absolute;
  top: -51px;
  transform: translateX(-50%);
}
