.mint-price {
  &-logo {
    height: auto;
    width: 32px;
  }

  &-text {
    font-size: 16px;
    margin-left: 12px;
  }
}
