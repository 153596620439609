@import '/src/theme/theme';

.player-controls__button {
  backdrop-filter: blur(24px);
  background: rgba(0, 0, 0, 0.2);
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  height: 40px;
  position: absolute;
  width: 40px;
  z-index: 52;

  &.type-play {
    backdrop-filter: none;
    background: transparent;
    font-size: 5rem;
    height: 5rem;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 5rem;

    @include media-breakpoint-down(md) {
      font-size: 2.5rem;
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  &.type-mute {
    bottom: 16px;
    left: 16px;

    @include media-breakpoint-down(lg) {
      bottom: 64px;
    }
  }

  &.type-unmute {
    bottom: 16px;
    left: 16px;

    @include media-breakpoint-down(lg) {
      bottom: 64px;
    }
  }

  svg path {
    fill: rgba(255, 255, 255, 0.7);
  }

  &:hover svg path {
    fill: #fff;
  }
}
