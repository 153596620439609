@import 'src/theme/theme';

.featured-page-header {
  background: url('/startup/assets/featured-header-bg.svg') center center no-repeat;
  background-position: top center;
  background-size: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 60px;
  min-height: 350px;
  padding: 80px 0;
  position: relative;
  text-align: center;

  @include media-breakpoint-up(md) {
    background-size: 1100px;
    margin-bottom: 100px;
    min-height: 500px;
  }

  @include media-breakpoint-up(lg) {
    background-size: auto;
    margin-bottom: 200px;
    min-height: 640px;
  }

  .container {
    position: relative;
  }

  h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(md) {
      font-size: 50px;    
    }

    @include media-breakpoint-up(lg) {
      font-size: 64px;    
    }
  }

  p {
    color: var(--bs-silver);
    font-size: 17px;
    margin: 0 auto 1.8em;
    max-width: 715px;

    @include media-breakpoint-up(lg) {
      font-size: 21px;    
    }
  }

  &-arrow {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  &-label {
    color: var(--bs-primary);
    font-size: 17px;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      font-size: 21px;    
    }
  }
}
