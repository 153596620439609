/* stylelint-disable selector-class-pattern */

.extension-modal {
  &_wrapper {
    .modal-content {
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.4);
    }

    .modal-dialog {
      max-width: 560px;
    }
  }

  &_iconContainer {
    align-items: center;
    border-radius: 40px;
    display: inline-flex;
    height: 40px;
    justify-content: center;
    width: 40px;

    svg {
      height: auto;
      width: 20px;
    }
  }
}

.dapp-core-component {
  &__dappModalStyles__dappModalContent {
    background-color: var(--bs-color-1) !important;
    border-color: var(--bs-color-1) !important;
  }

  &__main__data-field .dapp-core-component__main__textarea {
    background-color: var(--bs-body-bg) !important;
    border: 1px solid rgba(var(--bs-secondary-rgb), 0.3) !important;
  }

  &__transactionsToastList-styles__toasts {
    left: 20px !important;
    right: auto !important;
  
    &.dapp-core-component__transactionsToastList-styles__toastWrapper {
      background-color: var(--bs-color-1) !important;
      position: fixed !important;
    }
  }

  &__main__btn {
    @extend %btn-primary;
    background-color: var(--bs-secondary) !important;
    border-radius: $btn-border-radius !important;
    padding: $btn-padding-y $btn-padding-x !important;
    
    &:hover,
    &:active,
    &:focus,
    &:focus:active {
      background-color: var(--bs-color-4) !important;
    }
  }

  &__styles__close {
    color: var(--bs-silver) !important;
  }
}
