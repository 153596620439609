@import 'src/theme/theme';

.follow-button {
  background: var(--bs-primary);
  border: 0 !important;
  box-shadow: 0 4px 8px rgba(0, 31, 156, 0.16) !important;
  color: var(--bs-color-1) !important;
  display: flex;
  justify-content: center;
  padding: $btn-padding-y 18px;
  
  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background: var(--bs-primary-hover);
    color: var(--bs-color-1);
  }

  &:focus-visible {
    background: var(--bs-primary);
    color: var(--bs-color-1);
    outline: none;
  }
}
