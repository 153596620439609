@import 'theme/theme';

@mixin nft-page-content-padding-x {
  padding-left: 16px;
  padding-right: 16px;

  @include media-breakpoint-up(lg) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@mixin nft-page-content-padding-y {
  padding-bottom: 16px;
  padding-top: 16px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 24px;
    padding-top: 24px;
  }
}

@mixin nft-page-content-padding {
  @include nft-page-content-padding-x;
  @include nft-page-content-padding-y;
}
