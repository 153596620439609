.orders-accordion {
  border-radius: 12px;
  overflow: hidden;

  .accordion-item {
    margin-top: 8px;
  }

  .accordion-button {
    color: var(--bs-primary);
    justify-content: center;

    svg {
      margin-left: 6px;
    }
  }

  .accordion-body-content {
    padding: 9px 13px 25px;
  }
}
