@import './src/theme/theme';

.asset-card {
  &-title {
    font-family: $headings-font-family;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    &-col {
      align-items: center;
      display: flex;
      font-size: 18px;
      line-height: 1.2;

      @include media-breakpoint-up(md) {
        font-size: 24px;
      }
    }
  }

  .verified-badge {
    font-size: 0.6em;
    margin-left: 0.6em;
    margin-top: 0.2em;
  }
}
