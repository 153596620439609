@import './src/theme/theme';

.navbar-toggler {
  margin-right: 11px;
}

.logo {
  height: 38px;
  margin-right: 25px;
}


.navigation {
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.04);
  min-height: $header-height-mobile;

  @include media-breakpoint-up(md) {
    min-height: $header-height;
  }

  @include media-breakpoint-down(lg) {
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 0.65rem;
    padding-top: 0.65rem;
  }
}

.top-navigation-wrapper {
  background-color: var(--bs-color-2);
  min-width: 320px;
  position: absolute;
  top: 0;
  width: var(--viewport-width);
  z-index: 12;

  @include media-breakpoint-up(lg) {
    position: fixed;
  }

  .subdomain-home & {
    background-color: transparent;

    .navigation {
      box-shadow: none;
    }
  }
  
  &-hidden {
    display: none;
  }

  &.blurred {
    filter: blur(6px);
  }
}
