.user-details {
  font-size: 14px;
  line-height: 19px;
  max-width: 100%;
  
  &.disabled {
    pointer-events: none;
  }

  &-right {
    min-width: 0;
  }

  a {
    pointer-events: auto;
    text-decoration: none;
  }

  .user-image {
    border: 0;
    flex: 0 0 40px;
    height: 40px;
    margin-right: 12px;
    width: 40px;
  }
}
