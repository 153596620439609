@import 'nft-page/mixins';

.nft-page-offers {
  &-title {
    @include nft-page-content-padding-x;
    border-bottom: 1px solid var(--bs-color-3);
    color: var(--bs-silver);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  &-content {
    @include nft-page-content-padding-x();
    background-color: var(--bs-color-1);
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.04);
    padding-bottom: 16px;
    padding-top: 32px;
  }

  &-markets {
    @include nft-page-content-padding-x;
  }

  &-make {
    @include nft-page-content-padding-x;
    margin-bottom: 40px;
  }
}
