@import 'src/theme/theme';

.sidebar-balance-item {
  flex: 1 0 0%;
  padding-left: 5px;
  padding-right: 5px;

  &-title {
    color: var(--bs-silver);
    font-family: $headings-font-family;
    font-size: 12px;
    margin-bottom: 4px;
    margin-top: 28px;
  }

  &-amount {
    font-weight: bold;
    margin-right: 0.3em;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-egld {
    font-size: 18px;
  }

  &-token {
    font-size: 14px;
  }

  &-usd {
    color: var(--bs-silver);
    font-size: 14px;
    margin-bottom: 4px;
  }
}
