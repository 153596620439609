@import 'src/theme/theme';

.sidebar {
  background-color: rgba(0, 0, 0, 0);
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.3s ease-in-out;
  transition-property: opacity, background;
  z-index: 11;

  &.on-top {
    z-index: 30;
  }

  &.sidebar-opened {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 1;
    pointer-events: initial;

    .sidebar-inner {
      right: 0;
    }
  }

  &-inner {
    background-color: var(--bs-color-2);
    height: 100%;
    max-width: 100%;
    min-height: 100%;
    padding-top: #{$header-height-mobile};
    position: absolute;
    right: -#{$sidebar-width};
    transition: right 0.3s ease-out;
    width: $sidebar-width;

    @include media-breakpoint-up(md) {
      padding-top: #{$header-height};
    }

    .sidebar.on-top & {
      padding-top: 0;
    }
  }

  &-scrollable {
    height: 100%;
    overflow-y: auto;

    padding: 30px 16px 10px;
  }

  &-title {
    font-family: $headings-font-family;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
  }
}
