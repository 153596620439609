@import 'src/theme/theme';

.campaign-card {
  background-color: var(--bs-card-bg);
  border: $card-border-width solid var(--bs-card-border-color);
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  max-width: 100%;
  padding: 20px 0;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 100%;

  &:hover {
    background-color: var(--bs-card-bg-hover);
    border-color: var(--bs-card-hover-border-color);
    box-shadow: $card-box-shadow-hover;
  }

  &-link {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  
    &:focus-visible {
      border-radius: $card-border-radius;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
      outline: 2px solid var(--bs-primary);
    }
  }

  &-bottom {
    padding: 0 16px;
  }

  .campaign-status {
    font-size: 14px;
    margin-bottom: 20px;

    &-label {
      display: none;
    }

    &-text {
      font-weight: 400;
    }

    &-icon svg {
      width: 24px;
    }
  }
}
