@import 'src/theme/theme';

.holoride-campaign-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.36;
  margin-bottom: 16px;

  @include media-breakpoint-up(md) {
    font-size: 30px;
  }

  .verified-badge {
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }
}
