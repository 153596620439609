@import 'src/theme/theme';

.campaign {
  margin-bottom: 50px;
 
  @include media-breakpoint-up(lg) {
    margin-bottom: 100px;
  }

  .row {
    justify-content: space-between;
  }

  &-image-col {
    flex: 0 0 100%;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      flex: 0 0 57.5%;
      max-width: 57.5%;
    }
  }

  &-content-col {
    flex: 0 0 100%;
    margin: 0 auto;
    max-width: 436px;
    
 

    @include media-breakpoint-up(lg) {
      flex: 0 0 42.5%;
      margin: 0;
    }
  }
}
