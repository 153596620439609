@import './src/theme/theme';

.nft-media .not-supported {
  position: relative;
  z-index: 52;

  @include media-breakpoint-up(lg) {
    padding: 45px 95px;
  }

  img {
    max-width: 100%;
    width: 100%;

    @include media-breakpoint-up(lg) {
      max-height: 100%;
      width: auto;
    }
  }
}
