@import '/src/theme/theme';

.player-controls__playing {
  backdrop-filter: none;

  background: transparent;
  border: 0;
  font-size: 10rem;
  height: 10rem;
  left: 0;

  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;

  top: 50%;
  transform: translateY(-50%);

  width: 10rem;

  @include media-breakpoint-down(md) {
    font-size: 7rem;
    height: 7rem;
    width: 7rem;
  }

  path {
    fill: #fff;
  }
}
