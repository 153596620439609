@import './src/theme/theme';

.collection-card-images {
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  .gradient {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--bs-card-bg));
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

.collection-card-image {
  background: linear-gradient(180deg, var(--bs-body-bg) 0%, var(--bs-color-1) 50%, var(--bs-body-bg) 100%);
  background-size: 100% 200%;
  border-radius: 5px;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;

  &:nth-child(1) {
    z-index: 4;
  }

  &.active {
    z-index: 5;
  }

  img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  .collection-card-empty-circle {
    font-size: 14px;
  }
}

