.total-price-field {
  background: var(--bs-color-2);
  border: 2px solid var(--bs-color-3);
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  height: 67px;

  &-title {
    font-weight: bold;
  }

  &-image {
    height: 14px;
    width: 14px;
  }
}
