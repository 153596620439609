@import 'src/theme/theme';

.cta-section {
  margin-bottom: 90px;
  margin-top: 90px;
  position: relative;

  @include media-breakpoint-up(lg) {
    margin-bottom: 180px;
    margin-top: 180px;
  }

  .row {
    margin-left: -6px;
    margin-right: -6px;

    > div {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  &-title {
    font-family: $headings-font-family;
    font-size: 34px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 auto 17px;
    text-align: center;
    
    @include media-breakpoint-up(lg) {
      font-size: 40px;
    }

    span {
      background: $xspotlight-gradient;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

  }

  &-subtitle {
    color: var(--bs-silver);
    font-size: 18px;
    line-height: 1.15;
    margin: 0 auto 40px;
    max-width: 550px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 21px;
    }
  }
}
