.labeled-progress {
  $self: &;
  font-size: 16px;
  margin-bottom: 16px;

  &-labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  &-left {
    color: var(--bs-silver);
  }

  &-bar {
    background-color: var(--bs-color-3);
    border: 2px solid transparent;
    border-radius: 12px;
    height: 12px;
    position: relative;

    &::before {
      border-radius: 12px;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: var(--progress);
    }
        
    &.running {
      &::before {
        background: linear-gradient(90deg, var(--bs-green-dark) 0%, var(--bs-green-light) 100%);
      }
    }
        
    &.ended {
      &::before {
        background: linear-gradient(90deg, var(--bs-red) 27.05%, var(--bs-yellow) 100%);
      }
    }
  }

  &.running {
    #{$self}-bar::before {
      background: linear-gradient(90deg, var(--bs-green-dark) 0%, var(--bs-green-light) 100%);
    } 
  }

  &.ended {
    #{$self}-bar::before {
      background: linear-gradient(90deg, var(--bs-red) 27.05%, var(--bs-yellow) 100%);
    } 
  }

  &.small {
    font-size: 14px;

    #{$self}-bar {
      height: 8px;
    }
  }
}
