.labeled-price {
  &-icon {
    border-radius: 8px;
    overflow: hidden;
    width: 32px;

    > * {
      height: auto;
      width: 100%;
    }
  }
    
  &-text {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 700;
    margin-left: 12px;
  }

  .price-amount {
    font-size: 18px;
  }
}
