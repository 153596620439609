@import '/src/theme/theme';

.infinite-scroll {
  position: relative;

  &__load-trigger {
    bottom: 0;
    height: 404px;
    pointer-events: none;
    position: absolute;
    width: 100%;

    @include media-breakpoint-down(md) {
      height: 324px;
    }
  }
}
