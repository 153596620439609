@import 'src/theme/theme';

.roadmap-steps {
  padding-bottom: 40px;
  position: relative;

  &::after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, var(--bs-color-2) 60px, var(--bs-color-2) 100%);
    bottom: 0;
    content: '';
    left: 40px;
    position: absolute;
    top: 0;
    width: 7px;
    z-index: -1;

    @include media-breakpoint-up(md) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
 
}

.roadmap-step {
  margin-bottom: 50px;
  
  @include media-breakpoint-up(md) {
    margin-bottom: 50px;
  }
  
  &-quarter {
    background: var(--bs-color-2);
    border-radius: 50px;
    display: inline-block;
    font-weight: 400;
    padding: 14px 24px;
    
    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
      padding: 10px 20px;
    }
    
    @include media-breakpoint-up(md) {
      margin: 0 30px;
    }
  }
  
  &-left {
    align-content: end;
    color: var(--bs-silver);
    font-size: 16px;
    font-weight: 400;
    text-align: right;

    @include media-breakpoint-down(md) {
      align-content: start;
      margin-bottom: 16px;
      padding-left: 60px;
      text-align: left;
    }
    
    ul li {
      list-style-position: inside;
      margin-bottom: 0;
      text-align: right;

      @include media-breakpoint-down(md) {
        align-content: start;
        text-align: left;
      }
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &-right {
    color: var(--bs-silver);
    font-size: 16px;
    font-weight: 400;

    @include media-breakpoint-down(md) {
      align-content: start;
      margin-bottom: 16px;
      padding-left: 60px;
    }
    
    > *:last-child {
      margin-bottom: 0;
    }
  }
}
