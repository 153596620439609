@import '/src/theme/theme';

.holoride-campaign-countdown {
  color: var(--bs-primary);

  .duration-label {
    font-size: 24px;
    font-weight: 700;

    &-digits {
      font-weight: 700;
    }
  }
}
