@import 'src/theme/theme';

.my-nfts-modal {
  .modal-content {
    max-height: 80vh;
  }

  .modal-body {
    padding: 0;
  }

  .modal-dialog {
    max-width: 627px;
  }

  &-title {
    font-family: $headings-font-family;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 30px;
    position: relative;
    text-align: center;
    width: 100%;

    .modal-close {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-list {
    overflow-y: auto;
    width: 100%;
  }

  .loading-spinner {
    &-inner {
      height: 80px;
      width: 80px;
    }

    &-text {
      font-size: 10px;
    }
  }

  .card:not(:last-child) {
    margin-bottom: 5px;
  }
}
