.artist-account-social {
  &-icons {
    display: flex;
    flex-wrap: wrap;
  }

  &-icon {
    flex: 0 0 0%;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
