@import 'src/theme/theme';

.feed-card-asset {
  $self: &;

  &-outer {
    padding-top: 100%;
    position: relative;
  }

  &-inner {
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
  }

  img {
    border-radius: 6px;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      border-radius: 8px;
    }
  }

  &.big {
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }

    #{$self}-outer {
      padding: 0;
    }

    #{$self}-inner {
      height: initial;
      position: relative;
      width: initial;
    }

    img {
      height: auto;
      max-height: 320px;
      max-width: 100%;
      object-fit: initial;
      object-position: initial;
      width: auto;

      @include media-breakpoint-up(md) {
        max-height: 480px;
      }
    }
  }
}
