@import 'src/theme/theme';

.swiper-with-navigation {
  margin-top: -14px;
  position: relative;

  &-inner {
    margin-left: -6px;
    margin-right: -6px;
    overflow: hidden;
  }

  .swiper {
    overflow: visible;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 14px;

    &-wrapper {
      padding-bottom: 28px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    border-radius: 40px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    height: 36px;
    overflow: hidden;
    position: absolute;
    top: 40%;
    width: 36px;
    z-index: 200;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &::after {
      background: var(--bs-color-1) url('/startup/assets/arrow-right.svg') center center no-repeat;
      content: '';
      display: block;
      height: 100%;
      width: 100%;
    }

    &:hover::after {
      background-color: var(--bs-color-2);
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .swiper-button-prev {
    margin-left: -30px;

    &::after {
      transform: rotate(180deg);
    }
  }

  .swiper-button-next {
    margin-right: -30px;
  }

  .swiper-slide {
    width: 297px !important;

    @include media-breakpoint-up(md) {
      width: 384.66px !important;
    }
  }
}
