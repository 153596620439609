.holoride-campaign-redeem {
  .btn {
    margin-top: 32px;
    width: 100%;
  }

  &.win {
    background: linear-gradient(0deg, #1b260a, #1b260a), #d9d9d9;
    
    .holoride-action-title,
    .holoride-action-description,
    .holoride-campaign-countdown {
      color: #b6ff44;
    }

    .btn {
      background: #b6ff44;
      color: #0d0719;
    }

    
  }

  &.lost,
  &.nothing {
    background: linear-gradient(0deg, #26150a, #26150a), #d9d9d9;

    .holoride-action-title,
    .holoride-action-description,
    .holoride-campaign-countdown {
      color: #ff8d44;
    }

    .btn {
      background: #ff8d44;
      color: #0d0719;
    }
  }
}
