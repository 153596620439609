@import 'src/theme/theme';

.cta-section-box {
  align-items: flex-start;
  background-color: var(--bs-color-3);
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  display: flex;
  margin-bottom: 13px;
  margin-left: auto;
  margin-right: auto;
  padding: 26px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  width: 85%;

  @include media-breakpoint-up(sm) {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    padding: 37px 40px;
  }


  &-title {
    font-family: $headings-font-family;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 3px;

    @include media-breakpoint-up(lg) {
      font-size: 24px;
    }
  }

  &-subtitle {
    color: var(--bs-silver);
    font-size: 14px;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }
  }

  &-arrow {
    align-items: center;
    display: flex;
  }
}
