.traits-modal {
  &-title {
    color: var(--bs-light);
    font-size: 21px;
    font-weight: 700;
    line-height: 26px;
  }


  .clear-all-button {
    background: none;
    border: 0;
    color: var(--bs-silver);
    font-size: 16px;
    padding: 0;

    &:hover {
      opacity: 0.8;
    }
  }

  .filters-count {
    background-color: var(--bs-white-10);
    border-radius: 100px;
    color: var(--bs-silver);
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
  }
}
