.nft-title {
  align-items: center;
  display: flex;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.36;
  margin-bottom: 8px;

  &-text {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .verified-badge,
  .explorer-link {
    margin-left: 7px;
  }

  .explorer-link {
    display: inline-block;
    margin-bottom: 5px;
    vertical-align: middle;
  }
}
