.boxes-section-item {
  &-image {
    border-radius: 6px;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;

    &-wrapper {
      padding-top: 56%;
      position: relative;
      width: 100%;
    }
  }

  ul {
    padding: 0;
  }

  li {
    list-style-position: inside;
  }

  &-caption {
    align-items: center;
    color: var(--bs-silver);
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
