@import './src/theme/theme';


.toast-container {
  bottom: 15px;
  left: 15px;
  max-width: calc(100% - 30px);
  position: fixed;
  z-index: 100;


  @include media-breakpoint-up(sm) {
    bottom: 20px;
    left: 20px;
    max-width: calc(100% - 40px);
  }

  .toast {
    max-width: 100%;
    overflow: hidden;
  }
}
