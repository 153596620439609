@import '/src/theme/theme';

.offer-link-button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  svg {
    margin-right: 10px;

    @include media-breakpoint-down(sm) {
      margin-right: 3px;
    }
  }
}
