.sidebar-balance {
  border: 2px solid var(--bs-color-5);
  border-radius: 8px;
  box-sizing: border-box;
  padding-bottom: 20px;
  position: relative;

  &-icon {
    align-items: center;
    display: flex;
    left: 50%;
    position: absolute;
    top: -25px;
    transform: translateX(-50%);
    z-index: 1;

    svg {
      height: 48px;
      width: 48px;
    }
  }
}
