.hero {
  &-cover-container {
    background-position: center;
    background-size: cover;
    border-radius: 16px;
    height: 200px;
    overflow: hidden;
    position: relative;

    &-edit {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }

  &-cover-image {
    height: 100%;
    width: 100%;
  }
}
