@import 'src/theme/theme';

.simple-modal {
  .modal-dialog {
    max-width: 400px;

    @include media-breakpoint-up(md) {
      max-width: 480px;
    }
  }

  .modal-content {
    padding: 16px 24px;

    @include media-breakpoint-up(md) {
      padding: 24px 32px;
    }
  }

  &-icon {
    height: auto;
    margin: 0 auto 24px;
    max-width: 70px;

    @include media-breakpoint-up(md) {
      margin-bottom: 32px;
      max-width: 100px;
    }
  }

  &-title {
    font-family: $headings-font-family;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }

  &-content {
    color: var(--bs-silver);
    margin: 0 auto 24px;
    max-width: 90%;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      margin-bottom: 32px;
    }

    *:last-child {
      margin-bottom: 0;
    }

    strong {
      font-weight: 600;
    }
  }

  .btn {
    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }
}
