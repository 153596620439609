@import '/src/theme/theme';

.empty-column {
  flex: 1 0;
}

@include media-breakpoint-down(xl) {
  .empty-column {
    flex: none !important;
  }
}
