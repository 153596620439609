@import 'src/theme/theme';

.feed-card-account {
  border: 2px solid var(--bs-card-border-color);
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  &-link {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &-content {
    align-items: center;
    display: flex;
    margin: 24px;
  }

  &-cover {
    height: 120px;
    object-fit: cover;
    object-position: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: 150px;
    }

  }

  .user-image {
    align-self: flex-start;
    border-width: 4px;
    flex: 0 0 60px;
    margin-right: 16px;

    @include media-breakpoint-up(md) {
      flex: 0 0 100px;
    }
  }

  .username {
    font-size: 16px;
    line-height: 1.3;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  &-description {
    font-size: 12px;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
   
    &:not(:empty) {
      margin-top: 8px;

      @include media-breakpoint-up(md) {
        margin-top: 16px;
      }
    }
  }
}
