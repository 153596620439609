.multi-range-tooltip {
  background: var(--bs-color-3);
  border-radius: 10px;
  font-size: 14px;
  opacity: 0;
  padding: 4px 8px;
  position: absolute;
  top: -10px;
  transform: translateX(-50%) translateY(-100%);
  transition: opacity 0.3s ease-in-out;
}
