@import './src/theme/theme';

.collection-card-creator {
  min-width: 0;

  .user-info {
    backdrop-filter: blur(12px);
    background-color: rgba(var(--bs-primary-rgb), 0.1);
    border-radius: 30px;
    padding: 4px 16px 4px 4px;
  }

  .user-image {
    border: 0;
    border-radius: 100px;
    width: 2em;

    &-mask,
    &-image {
      border-radius: 100px;
    }
  }
}
