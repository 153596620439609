@import './src/theme/theme';

.price-field-usd {
  color: var(--bs-silver);
  font-size: 12px;
  left: 40px;
  pointer-events: none;

  .spacer {
    font-size: 14px;
    visibility: hidden;
  }
}
