.holoride-campaign-between {
  font-size: 16px;

  &-label {
    color: var(--bs-silver);
    margin-bottom: 10px;
  }

  &-text {
    font-weight: 600;
  }
}
