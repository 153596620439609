@import './src/theme/theme';

.collection-card-empty {
  align-items: center;
  background: linear-gradient(0deg, var(--bs-color-1) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;

  &-icon {
    color: var(--bs-silver);
  }

  &-circle {
    align-items: center;
    border: 1px solid var(--bs-color-5);
    border-radius: 50%;
    display: flex;
    font-size: 21px;
    height: 2.28em;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 30%;
    transform: translateX(-50%) translateY(-50%);
    width: 2.28em;
  }
}
  
