@import 'src/theme/theme';

.page-tabs {
  border-bottom: 1px solid var(--bs-color-3);
  flex-direction: column;
  position: relative;

  @include media-breakpoint-up(lg) {
    align-items: center;
    flex-direction: row;
  }

  &-tabs {
    display: flex;
    flex-grow: 1;
    min-width: 0;
    width: 100%;
    
    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }

  &-filters:not(:empty) {
    margin: 16px 0;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

  .dropdown-filter {
    .btn-group {
      flex-grow: 1;
    }

    .dropdown-menu {
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }

  &-counter {
    color: var(--bs-silver);
    font-weight: 500;
    padding-left: 16px;
  }

  .nav-link {
    border: 0;
    border-bottom: 5px solid transparent;
    color: var(--bs-white);
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    padding: 12px 0;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }

  .nav-link:focus-visible {
    outline: 0;
  }

  .nav-link.active {
    background: transparent;
    border-color: var(--bs-primary);
    color: var(--bs-white);
  }

  .nav-link.active:hover {
    border-color: var(--bs-primary);
  }

  .nav-link:hover {
    background: transparent;
    border-color: var(--bs-gray-dark);
    color: var(--bs-white);
  }

  &:not(.centered) {
    .swiper {
      margin-left: 0;
    }
  }

  .swiper {
    &-slide {
      width: auto !important;
    }
    // TODO
    // &-gradient {
    //   &-start,
    //   &-end {
    //     background: linear-gradient(90deg, var(--bs-body-bg) 0%, rgba(0, 0, 0, 0) 100%);
    //     height: 100%;
    //     left: 0;
    //     pointer-events: none;
    //     position: absolute;
    //     top: 0;
    //     width: 100px;
    //     z-index: 2;
    //   }
  
    //   &-end {
    //     background: linear-gradient(-90deg, var(--bs-body-bg) 0%, rgba(0, 0, 0, 0) 100%);
    //     left: auto;
    //     right: 0;
    //   }
    // }
  }
}
