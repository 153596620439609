@import '/src/theme/theme';

.details-countdown-timer {
  color: var(--bs-orange);
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;
}

.timer-numbers-wrapper {
  margin: 0 8px;

  &:last-child {
    margin-right: 0;
  }

  p {
    margin-bottom: 0;
  }
}

.timer-numbers-label {
  color: var(--bs-silver);
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
}
