.free-label {
  background: rgba(var(--bs-green-dark-rgb), 0.1);
  border-radius: 3px;
  color: var(--bs-green-dark);
  font-size: 12px;
  font-weight: 400;
  padding: 4px 6px;
  pointer-events: none;
  text-align: center;
}
