@import 'src/theme/theme';

.nft-auction-details {
  position: relative;

  &-inner {
    display: flex;
    flex-direction: column;
    position: relative;


    @media (min-width: 376px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @include media-breakpoint-up(md) {
      padding: 0 16px;
    }
  }
}
