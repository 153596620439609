@import './theme';

html,
body {
  counter-reset: section;
  height: 100vh;
  overflow: hidden;
}

body {
  min-width: 320px;

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }

  > iframe {
    margin-top: -1px;
  }
}

#root {
  height: 100vh;
  overflow: auto;
}

img {
  max-width: 100%;
}

a {
  color: var(--bs-primary);

  &:hover {
    color: var(--bs-primary-hover);
  }
}
