@import './src/theme/theme';

.collection-stats-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 10px;
  
  @include media-breakpoint-up(xl) {
    flex: 1 1 auto;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 12px;
    padding: 6px;
  }

  @include media-breakpoint-between(lg, xl) {
    &:nth-child(4n) {
      border-right: 0 !important;
    }
  }

  @include media-breakpoint-between(sm, lg) {
    &:nth-child(3n + 1) {
      border-right: 0 !important;
    }
  }

  @include media-breakpoint-down(sm) {
    &:nth-child(2n + 1) {
      border-right: 0 !important;
    }
  }

  @include media-breakpoint-down(lg) {
    &:first-child {
      flex: 0 0 100%;
    }
  }

  &:not(:last-child) {
    border-right: 1px solid var(--bs-color-3);
  }
  
  &-value {
    font-size: 14px;
    
    &-value {
      font-size: 20px;
      font-weight: bold;
    }  
  }  
  
  &-label {
    color: var(--bs-silver);
    font-size: 14px;
    margin-top: 5px;
  }
}
