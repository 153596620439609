@import 'src/theme/theme';

.asset-card-link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &:focus-visible {
    border-radius: $card-border-radius;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    outline: 2px solid var(--bs-primary);
  }
}
