@import 'src/theme/theme';

.feed {
  margin-bottom: 3rem;

  .loading-spinner {
    &-inner {
      height: 80px;
      width: 80px;
    }

    &-text {
      font-size: 10px;
    }
  }
}
