@import 'src/theme/theme';

.account-notifications {
  margin-right: 12px;
  margin-top: 2px;

  @include media-breakpoint-down(md) {
    margin-right: 7px;
  }

  &-button {
    align-items: center;
    appearance: none;
    background-color: var(--bs-color-1);
    border: transparent;
    border-radius: 100px;
    color: #838c9a;
    cursor: pointer;
    display: flex;
    font-size: 17px;
    height: 48px;
    justify-content: center;
    outline: none;
    padding: 0;
    width: 48px;
      
    @include media-breakpoint-down(md) {
      height: 40px;
      padding: 11px;
      width: 40px;
    }

    &::after {
      content: none;
    }
      
    &:hover,
    &:focus,
    &:active {
      background-color: var(--bs-color-2);
    }
      
    &:focus-visible {
      background-color: var(--bs-color-3);
    }

    &.has-notifications::before {
      background-color: var(--bs-red);
      border-radius: 5px;
      content: '';
      height: 22%;
      left: 83%;
      position: absolute;
      top: 17%;
      transform: translateX(-50%) translateY(-50%);
      width: 22%;
    }
  }

  &-title {
    background-color: var(--bs-color-2);
    font-family: $headings-font-family;
    font-size: 18px;
    padding: 14px 24px;
  }

  &-list {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    margin-top: 10px !important;
    padding: 0;
    pointer-events: none;
    right: -53px !important;
    width: 290px;

    @include media-breakpoint-up(sm) {
      width: 466px;
    }

    @include media-breakpoint-up(md) {
      right: -66px !important;
    }

    &-inner {
      background-color: var(--bs-color-1);
      border-radius: 6px;
      box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
      float: right;
      margin-left: auto;
      overflow: hidden;
      pointer-events: auto;
    }

    &-scroll {
      max-height: 320px;
      overflow-y: auto;
      padding-bottom: 6px;
    }

  }

  &-item {
    align-items: center;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    display: flex;
    font-size: 14px;
    padding: 16px 40px 16px 16px;
    vertical-align: middle;

    @include media-breakpoint-up(sm) {
      padding-right: 60px;
    }

    &:hover {
      background-color: var(--bs-color-2);
      border-bottom: 1px solid var(--bs-color-4);
      border-top: 1px solid var(--bs-color-4);

      .account-notifications-item-icon {
        background-color: var(--bs-color-3);
      }
    }

    &-text {
      white-space: initial;
    }

    &-name {
      color: var(--bs-primary);
    }

    &-icon {
      align-items: center;
      background-color: var(--bs-color-3);
      border-radius: 100px;
      display: flex;
      flex: 0 0 32px;
      height: 32px;
      justify-content: center;
      margin-right: 0.7em;
      width: 32px;

      svg {
        display: inline-block;
        height: auto;
        width: 1.2em;
      }
    }

    &.outbidded {
      .account-notifications-item-icon {
        background-color: rgba(var(--bs-orange-rgb), 0.1);
      }
    }

    &.won {
      .account-notifications-item-icon {
        background-color: rgba(var(--bs-yellow-rgb), 0.1);
      }
    }
  }

  .empty-container {
    font-size: 12px;
    padding: 30px;
    text-align: center;

    @include media-breakpoint-up(sm) {
      padding-right: 30px 60px;
    }
  }
}
