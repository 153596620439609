
@import 'src/theme/theme';

.price-range-token {
  display: flex;
  flex-direction: column-reverse;

  @include media-breakpoint-up(md) {
    align-items: center;
    flex-direction: row;
  }

  .price-range {
    margin-top: 8px;

    @include media-breakpoint-up(md) {
      margin: 0 8px 0 0;
    }
  }
}
