.form-control {
  border-width: 0;
  border-bottom-width: 2px;

  &.icon-none {
    padding-left: 24px;
  }

  &.with-icon {
    padding-left: 1.5rem;
  }

  &-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.form-select {
  border-width: 0;
  border-bottom-width: 2px;
  padding-left: 0;

  option {
    background-color: var(--bs-color-4);
  }
}

.form-switch {
  margin-bottom: 20px;
  padding: 0;

  .form-check-input {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28142, 147, 150, 1%29'/%3e%3c/svg%3e");
    border-color: var(--bs-silver);
    float: none;
    height: 1.5rem;
    margin: 0;
    opacity: 1;
    
    &-wrapper {
      position: relative;
    }

    &:not(:checked):focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28142, 147, 150, 1%29'/%3e%3c/svg%3e");
    }

    &:not(:checked):hover {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e");
      border-color: var(--bs-white);
    }

    &:checked {
      background-color: var(--bs-primary);
      border-color: var(--bs-primary);
      opacity: 1;
    }
    
    &:checked:hover {
      background-color: var(--bs-primary);
      opacity: 1;
    }

    &-on,
    &-off {
      font-size: 0.8em;
      left: 0.7em;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
    }

    &-off {
      left: auto;
      opacity: 1;
      right: 0.7em;
    }

    &:checked {
      ~ .form-check-input-off {
        opacity: 0;
      }
        
      ~ .form-check-input-on {
        color: var(--bs-dark);
        opacity: 1;
      }
    }

    &.black {
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 1%29'/%3e%3c/svg%3e");
      border-color: var(--bs-dark);
      height: 1.5rem;
      opacity: 1;

      ~ .form-check-input-on,
      ~ .form-check-input-off {
        color: var(--bs-dark);
      }

      &:not(:checked):focus {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 1%29'/%3e%3c/svg%3e");
      }

      &:not(:checked):hover {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 1%29'/%3e%3c/svg%3e");
        border-color: var(--bs-dark);
      }

      &:not(:checked):hover {
        opacity: 1;
      }

      &:checked {
        background-color: var(--bs-primary);
        border-color: var(--bs-dark);
        opacity: 1;
      }
    
      &:checked:hover {
        background-color: var(--bs-primary);
        opacity: 1;
      }
    }
  }
}


.clickable {
  cursor: pointer;
}

.label-field {
  color: var(--bs-silver);
  margin-bottom: 2px;
}

.icon-field {
  color: var(--bs-silver);
  font-size: 12px;
}

.nerds-form-field {
  margin-bottom: 25px;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-bottom: 33px;
  }

  .text-danger {
    bottom: -21px;
    position: absolute;
  }

  .react-select {
    &__control {
      background-color: transparent;
      border-color: $input-border-color;
      border-radius: 0;
      border-width: 0 0 2px;
     
      min-height: 41px;

      &:hover {
        border-color: $input-border-color;
      }

      &--is-focused {
        border-color: $input-focus-border-color;
        box-shadow: none;
        outline: 0;

        &:hover {
          border-color: $input-focus-border-color;
        }
      }
    }

    &__input-container {
      color: inherit;
    }

    &__single-value {
      color: var(--bs-light);
    }

    &__indicator-separator {
      background-color: var(--bs-color-4);
    }

    &__menu {
      background-color: var(--bs-color-2);
      box-shadow: 0 0 0 1px rgba(var(--bs-color-5-rgb), 0.1), 0 4px 11px rgba(var(--bs-color-5-rgb), 0.1);
    }

    &__option {
      &--is-focused {
        background-color: var(--bs-color-3);
      }

      &--is-selected {
        background-color: var(--bs-primary);
      }

      &:active {
        background-color: var(--bs-color-3);
      }
    }
  }
}
