@import './src/theme/theme';

.data-card-action {
  > * {
    margin-left: 8px;

    @include media-breakpoint-up(lg) {
      margin-left: 16px;
    }
  }
}
