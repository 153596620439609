@import './src/theme/theme';

.asset-card-badge { 
  @include gradient-box($bg-color: var(--bs-color-2), $border-width: 1px, $border-radius: 30px);
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.35;
  margin-right: 4px;
  pointer-events: none;

  @include media-breakpoint-up(md) {
    font-size: 14px;
  }

  &-inner {
    align-items: center;
    display: flex;
    min-height: 1.7em;
    padding: 4px 12px;

    svg {
      height: auto;
      width: 18px;
    }
  }

  &-suffix {
    margin-left: 7px;
    opacity: 0.5;
  }

  &.badge-time-left {
    left: 0;
    right: auto;
  }

  &.badge-ended {
    @include gradient-box($bg-color: var(--bs-color-2), $start:var(--bs-color-5),
    $end: var(--bs-silver) ,$border-width: 1px, $border-radius: 30px);
  }

  &.badge-top-bidder {
    @include gradient-box($bg-color: var(--bs-color-2), $start: var(--bs-green-light), 
    $end: var(--bs-green-dark),$border-width: 1px, $border-radius: 30px);
  }
}
