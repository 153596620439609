@import 'src/theme/theme';

.feed-card-small-preview {
  flex: 0 0 80px;
  position: relative;
  
  @include media-breakpoint-up(md) {
    flex: 0 0 120px;
  }

  .user-image {
    border-width: 4px;

    &-mask,
    &-image {
      border-radius: 8px;
    }
  }
}
