.campaign-card-image {
  background-size: cover;
  margin-bottom: 16px;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  will-change: transform;

  img {
    height: 100%;
    left: 0;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
  }
}
