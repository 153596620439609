@import 'src/theme/theme';

.home-welcome {
  margin-bottom: 100px;
  position: relative;
  
  @include media-breakpoint-up(lg) {
    margin-bottom: 200px;
  }

  .row {
    justify-content: center;
  }
  
  &-background {
    background-color: var(--bs-color-2);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 22px 7px;
  }

  &-image {
    border-radius: 6px;
    max-width: 100%;

    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }
  }
  
  &-button {
    align-self: flex-start;
  }
  
  &-highlight {
    color: var(--bs-primary);
    font-family: $headings-font-family;
  }
  
  &-title {
    font-family: $headings-font-family;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  
  &-description {
    color: var(--bs-silver);
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
  }
}
