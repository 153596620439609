@import '/src/theme/theme';

.other-auction-action {
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  padding: 8px 16px;

  &-disabled {
    cursor: text;
    opacity: 0.65;
    text-decoration: none;

    &:hover {
      color: var(--bs-primary);
      opacity: 0.65;
    }
  }
}
