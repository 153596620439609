.footer-social-link {
  color: var(--bs-silver);
  font-size: 16px;

  &:hover {
    color: var(--bs-light);
  }

  &:not(:last-child) {
    margin-right: 1.5rem;
  }
}
