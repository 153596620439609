.maiar-ledger {
  /* stylelint-disable-next-line */
  .login-modal-content_cardBody {
    margin: 0 !important;
    padding: 0 !important;

    .form-check-input + .form-check-label {
      margin-left: 0.5rem !important;
    }
  }

  .form-check-input {
    background-color: var(--bs-light);
  }

  .form-check-input:checked {
    background-color: var(--bs-primary);
  }

  th {
    color: var(--bs-light);
  }

  td {
    color: var(--bs-light);
  }

  .dapp-core-component__main__card {
    background: var(--bs-body-bg);
    border: 0;
  }
}
