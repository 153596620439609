@import 'src/theme/theme';

.middleground-surface {
  background: var(--bs-body-bg);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
  padding-top: #{$header-height-mobile + 30px};
  position: fixed;
  top: 0;
  width: 100%;

  @include media-breakpoint-up(md) {
    padding-top: #{$header-height + 65px};
  }

  &.blurred {
    filter: blur(6px);
  }
}
