@import './src/theme/theme';

.footer {
  &-section-link {
    color: var(--bs-light);
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;

    &:hover {
      color: var(--bs-light);
      text-decoration: underline;
    }
  }
}
