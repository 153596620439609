@import 'src/theme/theme';

.campaign-tier-card {
  &:not(:last-child) {
    margin-bottom: 11px;
  }

  .card-with-header-content {
    padding-top: 24px;
  }

  &-icon {
    margin-right: 11px;
  }

  &-title {
    flex-grow: 1;
    font-family: $headings-font-family;
    font-size: 16px;
    font-weight: 700;
  }

  &-description {
    padding: 0 8px;
  }
}
