.sidebar-connect-button {
  $self: &;
  background: var(--bs-color-1);
  border: 1px solid var(--bs-color-1);
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  margin-bottom: 16px;
  padding: 23px 24px;
  text-align: left;

  svg {
    height: auto;
    max-width: 100%;

    .maiar-extension-1,
    .maiar-icon-1 {
      fill: var(--bs-primary);
    }

    .ledger-icon-1,
    .web-wallet-1 {
      fill: var(--bs-primary);
    }
  }

  span {
    flex: 1;
  }

  &-icon {
    height: 24px;
    margin-right: 16px;
    width: 24px;
  }

  &-arrow {
    color: var(--bs-primary);
    height: 24px;
    margin-left: 16px;
    position: relative;
    right: 0;
    text-align: right;
    transition: all 0.3s ease-in-out;
    width: 24px;
  }

  &:hover {
    border: 1px solid var(--bs-primary);
    #{$self}-arrow {
      right: -6px;
    }
  }

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}
