@import './src/theme/theme';

.data-card-header {
  .btn {
    display: block;
    margin-left: 1rem;

    @include media-breakpoint-down(md) {
      font-size: 12px;
      margin-left: 0.6rem;
      padding: 13px 14px;
    }

    span {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
