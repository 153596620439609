@import './src/theme/theme';

.account-link {
  background-color: transparent;
  border: 0;
  border-radius: 100px;
  padding: 3px;

  &:hover,
  &:active {
    background-color: var(--bs-color-2);
  }

  &:focus-visible {
    background-color: var(--bs-primary);
    outline: none;
  }
  
  .user-image {
    border: 0;
    border-radius: 100px;
    width: 40px;
    
    @include media-breakpoint-up(md) {
      width: 48px;
    }

    &-mask,
    &-image {
      border-radius: 100px;
    }    
  }
}
