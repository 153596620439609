@import 'src/theme/theme';

.subdomain-social-links {
  align-items: center;
  background-color: var(--bs-color-2);
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
  padding: 32px;
  
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  
  &-content {
    margin-top: 15px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
  
  .subdomain-social-logo {
    max-height: 60px;
    max-width: 250px;;
  }

  .social-link-wrapper {
    color: var(--bs-light);
    
    &:hover {
      background-color: var(--bs-primary);

    }
  }
}
