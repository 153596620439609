@import 'src/theme/theme';

.feed-card {
  background: var(--bs-card-bg);
  border: $card-border-width solid var(--bs-card-border-color);
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  box-sizing: border-box;
  margin-bottom: 8px;
  padding: 2px;
  pointer-events: none;
  position: relative;
  transition: all 0.3s ease-in-out;

  @include media-breakpoint-up(md) {
    margin-bottom: 10px;
  }

  a {
    pointer-events: auto;
  }

  &:hover {
    background-color: var(--bs-card-bg-hover);
    border-color: var(--bs-card-hover-border-color);
    box-shadow: $card-box-shadow-hover;
  }

  &-top {
    pointer-events: none;
    position: relative;
  }
}
