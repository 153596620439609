@import './src/theme/theme';

.empty {
  &-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    &.fullscreen {
      flex-grow: 1;
      padding: 150px 0;

      @include media-breakpoint-up(md) {
        padding: 80px 0;
      }
    }

    &.delayed-display {
      animation: 0.3s delayedDisplay;
      animation-fill-mode: forwards;
      visibility: hidden;
    }
  }

  &-circle {
    align-items: center;
    background-color: var(--bs-color-3);
    border-radius: 50%;
    display: flex;
    height: 72px;
    justify-content: center;
    width: 72px;
  }

  &-icon {
    color: var(--bs-silver);
  }
  
  &-illustration {
    margin-bottom: 16px;
  }
  
  &-title {
    font-family: $headings-font-family;
    font-size: 16px;
    margin-bottom: 0;
  }
  
  &-message {
    color: var(--bs-silver);
    margin-bottom: 24px;
  }
}

@keyframes delayedDisplay {
  99% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}
