@import 'src/theme/theme';

.subdomain-hero {
  align-items: flex-end;
  background-position: center;
  background-size: cover;
  color: var(--bs-light);
  display: flex;
  height: 100vh;
  margin-bottom: 50px;
  min-height: 400px;
  padding-bottom: 50px;
  position: relative;
 
  @include media-breakpoint-up(md) {
    margin-bottom: 200px;
  }

  &-logo {
    max-height: 140px;
  }

  .container {
    position: relative;
  }

  &-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--bs-body-bg) 100%);
    bottom: 0;
    height: 400px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &-arrow {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
