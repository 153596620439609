@import 'src/theme/theme';

.feed-card-user-image {
  margin-right: 12px;

  @include media-breakpoint-up(md) {
    margin-right: 16px;
  }

  .user-image {
    align-self: initial;
    border: 0;
    width: 40px;
    
    @include media-breakpoint-up(md) {
      width: 64px;
    }
  }
}
