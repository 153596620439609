.nft-current-bid {
  margin-bottom: 24px;

  &-title {
    color: var(--bs-silver);
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 8px;
  }

  &-image {
    height: 40px;
    margin-right: 10px;
    width: 40px;
  }
}
