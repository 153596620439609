$img-border-radius: 38%;

.user-image {
  align-self: center;
  background-color: var(--bs-color-3);
  border: 4px solid var(--bs-color-3);
  border-radius: $img-border-radius;
  max-width: 100%;
  width: 144px;

  &.is-nft {
    width: 144px;
  }
  
  &.disabled a {
    pointer-events: none;
  }
  

  &-mask {
    background-color: var(--bs-color-1);
    border-radius: $img-border-radius;
    display: block;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;
  }

  &-image {
    border-radius: $img-border-radius;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.is-nft {
    background-color: transparent;
    border: 0;

    svg path {
      fill: var(--bs-body-bg);
      stroke: var(--bs-body-bg);
    }
  }
}
