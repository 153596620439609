@import './src/theme/theme';

.subdomain-layout {
  padding-top: #{$header-height-mobile + 30px};

  @include media-breakpoint-up(md) {
    padding-top: #{$header-height + 65px};
  }

  .subdomain-home & {
    padding-top: 0;
  }
}
