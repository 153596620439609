@import './src/theme/theme';

.search-input {
  background-color: var(--bs-body-bg);
  border: 2px solid var(--bs-body-bg);
  border-radius: 100px;
  box-shadow: 0 10px 24px transparent;
  font-size: 16px;
  height: 48px;
  padding: 17px 20px;
  padding-right: 48px;

  &:focus {
    background-color: var(--bs-color-1);
    border-color: var(--bs-body-bg);
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.08);

    + .search-input-icon {
      color: var(--bs-light);
    }
  }

  &-wrapper {
    margin: 0 auto;
    position: relative;
    width: 100%;

    @include media-breakpoint-down(lg) {
      max-width: initial;
    }
  }

  &-icon {
    color: var(--bs-silver);
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.15s ease-in-out;
  }
}
