.campaign-card-price {
  &-logo {
    height: auto;
    width: 24px;
  }
    
  &-text {
    font-size: 14px;
    margin-left: 12px;
  }
}
