@import './src/theme/theme';

.collection-card {
  &-title {
    font-family: $headings-font-family;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    &-col {
      align-items: center;
      display: flex;
      font-size: 28px;

      @include media-breakpoint-up(md) {
        font-size: 32px;
      }
    }
  }

  .verified-badge {
    font-size: 0.48em;
    margin-left: 0.3em;
    margin-top: 0.2em;
  }
}
