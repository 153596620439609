// nav
$navbar-light-active-color: var(--bs-light);
$navbar-light-color: var(--bs-primary);

// borders
$border-radius: 8px;

// fonts
$font-family-sans-serif: 'Inter', sans-serif;
$headings-font-family: 'Roobert', sans-serif;
$font-family-monospace: 'Azeret Mono';
$font-size-base: 1rem;
$font-size-default: 12px;

// accordion
$accordion-border-width: 0;
$accordion-icon-transform: rotate(0deg);

//buttons
$btn-font-size: 16px;
$btn-line-height: 1.25;
$btn-border-radius: 100px;
$btn-padding-x: 22px;
$btn-padding-y: 14px;
$btn-border-width: 0;
$btn-font-weight: 500;

$btn-font-size-lg: 16px;
$btn-padding-x-lg: 32px;
$btn-padding-y-lg: 15px;
$btn-border-radius-lg: 100px;

// grid
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1242px
);

// inputs
$input-font-size: $font-size-base;
$input-focus-box-shadow: none;
$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;
$input-padding-y: 9.5px;
$input-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$form-switch-width: 2.85em;

// select 
$form-select-border-radius: 0;
$form-select-focus-box-shadow: none;
$form-select-border-radius: 0;
$form-select-border-radius-sm: 0;
$form-select-border-radius-lg: 0;

// labels
$form-label-margin-bottom: 0.5rem;
$form-label-font-size: $font-size-base;
$form-label-font-style: normal;
$form-label-font-weight: null;

// modals
$modal-content-border-radius: 6px;
$modal-content-padding: 40px;
$modal-content-padding-mobile: 20px;
$modal-sm: 300px;
$modal-md: 530px;
$modal-lg: 800px;
$modal-xl: 1040px;

// header
$header-height-mobile: 66px;
$header-height: 88px;

// toast
$toast-border-radius: 8px;
$toast-padding-y: 0.5rem;
$toast-box-shadow: 0 10px 24px rgba(var(--bs-color-5-rgb), 0.3);
$toast-header-background-color: var(--bs-color-2);
$toast-background-color: var(--bs-color-3);
$toast-border-color: var(--bs-color-2);
$toast-border-width: 2px;
$toast-color: var(--bs-silver);
$toast-header-color: var(--bs-light);
$toast-max-width: 300px;

// cards
$card-border-radius: 6px;
$card-border-width: 1px;
$card-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
$card-box-shadow-hover: 0 14px 30px rgba(0, 0, 0, 0.05);

// misc
$sidebar-width: 470px;
