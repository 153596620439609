@import 'src/theme/theme';

.home-section {
  margin-bottom: 70px;
  overflow: hidden;
  padding-top: 30px;
    
  @include media-breakpoint-up(md) {
    margin-bottom: 170px;
  }

  &:last-child {
    margin-bottom: 0;
    margin-top: -30px;
    padding-bottom: 100px;

    @include media-breakpoint-up(md) {
      margin-top: -100px;
    }
  }
  
  &-header {
    margin: 0 auto 21px;

    @include media-breakpoint-up(md) {
      margin-bottom: 64px;
    }
  }
    
  &-title {
    font-family: $headings-font-family;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 16px;
    position: relative;
    text-align: center;
    
    @include media-breakpoint-up(md) {
      font-size: 48px;
    }

    span {
      background: $xspotlight-gradient;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .btn {
      display: block;
      margin-left: 1rem;

      @include media-breakpoint-down(md) {
        font-size: 12px;
        margin-left: 0.6rem;
        padding: 13px 14px;
      }

      span {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }
  
  &-description {
    color: var(--bs-silver);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    max-width: 658px;
    position: relative;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 21px;
    }
    
    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
    }
    
    &-col {
      flex-grow: 3;
    }
  }
}
