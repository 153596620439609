@import './src/theme/theme';

.navbar-collapse {
  @include media-breakpoint-up(lg) {
    flex-grow: initial;
  }

  .link-item {
    display: flex;
    
    @include media-breakpoint-down(md) {
      flex-grow: 1;
    }

    &:not(:last-child) {
      .nav-link {
        margin-right: 5px;
      }
    }

    .nav-link {
      align-items: center;
      border: 1px solid transparent;
      border-radius: 100px;
      color: var(--bs-light);
      display: flex;
      font-size: 16px;
      font-weight: 500;
      padding: 8px 16px;
      width: 100%;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        justify-content: center;
      }

      svg {
        height: auto;
        margin-right: 8px;
        vertical-align: initial;
        width: 16px;
      }

      &:focus {
        color: var(--bs-light);
      }

      &:hover {
        background-color: var(--bs-color-2);
        color: var(--bs-light);
      }

      &.active {
        background: var(--bs-color-2);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
      }
    }
  }
}

.navbar-nav {
  background: var(--bs-body-bg);
  border: 4px solid transparent;
  border-radius: 100px;
  flex-direction: row;

  @include media-breakpoint-down(md) {
    margin: 0 0 30px;
  }
}
