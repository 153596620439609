@import 'src/theme/theme';

.profile-photo-container {
  margin-top: -70px !important;

  &-edit-container {
    align-items: flex-end;
    margin-left: -30px;
    z-index: 1000;
  }

  &-edit-avatar {
    align-items: center;
    background-color: var(--bs-color-2);
    border: 1px solid var(--bs-color-1);
    border-radius: 50%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    cursor: pointer;
    height: 36px;
    width: 36px;

    &:hover {
      background-color: var(--bs-color-3);
      border-color: var(--bs-color-2);
      color: var(--bs-light);
    }
  }

  .user-image {
    z-index: 2;

    &:not(.is-nft) {
      border-color: var(--bs-body-bg);
      border-width: 8px;
     
    }
  }
}

@include media-breakpoint-down(lg) {
  .profile {
    &-photo {
      &-container {
        display: flex;
        flex: none;
        justify-content: center;
      }
    }
  }
}
