@import 'src/theme/theme';

.duration-label {
  font-weight: 500;

  &-element {
    display: none;

    &:nth-child(1),
    &:nth-child(2) {
      display: inline;
    }

    &:nth-child(1) {
      .duration-label-dots {
        display: none;
      }
    }
  }

  &-digits {
    font-family: $font-family-monospace;
    font-weight: 400;
  }

  &-dots {
    display: inline-block;
    margin: 0 0.2em;
  }
}
