@import 'src/theme/theme';

.profile-card {
  background: var(--bs-card-bg);
  border: $card-border-width solid var(--bs-card-border-color);
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  box-sizing: border-box;
  margin-bottom: 8px;
  position: relative;
  transition: all 0.3s ease-in-out;

  @include media-breakpoint-up(md) {
    margin-bottom: 10px;
  }

  &:hover {
    background-color: var(--bs-card-bg-hover);
    border-color: var(--bs-card-hover-border-color);
    box-shadow: $card-box-shadow-hover;
  }

  &-link {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  
    &:focus-visible {
      border-radius: $card-border-radius;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
      outline: 2px solid var(--bs-primary);
    }
  }

  &-cover {
    background-position: center;
    background-size: cover;
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
    overflow: hidden;
    padding-top: 49%;
    position: relative;
    width: 100%;
    
    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .username {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  &-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -15%;
    padding: 0 12px 12px;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(sm) {
      padding: 0 24px 24px;
    }
  }

  .user-image {
    border-width: 4px;
    margin-bottom: 16px;
    width: 38%;

    &,
    &-mask ,
    &-image {
      border-radius: 40%;
    }
  }

  &-description {
    -webkit-box-orient: vertical;
    color: var(--bs-silver);
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    flex-grow: 1;
    font-size: 12px;
    -webkit-line-clamp: 4;
    line-height: 1.2;
    margin: 0;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      font-size: 14px;
    }

    &:not(:empty) {
      margin-bottom: 24px;
    }
  }

  .btn {
    position: relative;
    width: 100%;
    z-index: 10;

    @include media-breakpoint-down(md) {
      font-size: 12px;
      padding: 10px;
    }
  }
}
